import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';


const Leads = () => {
  // Sample data for the chart representing categories
  const [series] = useState([25, 15, 44, 55]); // Adjusted series data
  const options = {
    chart: {
      width: '100%',
      height: '100%',
      type: 'pie',
    },
    labels: ['Demoschedule', 'Interest', 'Join', 'Pending Payment'], // Updated labels
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + '%'];
      },
    },
    legend: {
      show: false,
    },
    title: {  // Adding a title for the chart
      text: 'Leads Overview',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',  // Color of the title
      }
    }
  };

  return (
    <div className="apex-chart-container">
      <div className="chart-wrapper">
        <ReactApexChart options={options} series={series} type="pie" />
      </div>
      
    </div>
  );
};

export default Leads;
