import React from 'react';
import Chart from 'react-apexcharts';

function UpcommingClass() {
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ['#3498db'], 
    plotOptions: {
      bar: {
        borderRadius: 12, 
        columnWidth: '55%', 
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val} Classes`,
      offsetY: -12, 
      style: {
        fontSize: '12px',
        fontWeight: '600',
        colors: ['#f4f4f4'], 
      },
    },
    title: {
      text: 'Daily Classes',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#2c3e50', 
      },
    },
    xaxis: {
      categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      labels: {
        style: {
          colors: '#2c3e50',
          fontSize: '13px',
          fontWeight: '600',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Classes',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#2c3e50',
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          color: '#2c3e50',
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      shared: true,
      intersect: false,
    },
    grid: {
      borderColor: '#d1d5db', 
      strokeDashArray: 4,
    },
  };

  const series = [
    {
      name: 'Classes Attended',
      data: [5, 8, 3, 7, 10, 6, 4],
    },
  ];

  return (
    <div className='position-y-fixed'>
    <div className='rounded'
      style={{
        
        padding: '25px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
       
        backgroundColor: '#ffffff',
        border:'1px solid gray',
       
        textAlign: 'center', 
      }}
      
    >
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
    </div>
  );
}


export default UpcommingClass;
