import React, { useEffect, useState } from "react";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import SecurityHeaders from "../../context/SecurityHeaders";
import Accordion from "react-bootstrap/Accordion";
import Avatar from "@mui/material/Avatar";
import Localbase from "localbase";
const QuizAttemptHistory = () => {
  const [attempts, setAttempts] = useState(null);
  const [questions, setQuestions] = useState(null);
  let db = new Localbase("db");
  const GetQuizzes = async () => {
    const data = new FormData();
    const res = await axios.post(
      ENDPOINT + "get-quiz-history.php",
      data,
      SecurityHeaders,
    );

    if (res) {
      if (res.data) {
        if (res.data.status === "success") {
          setAttempts(res.data.data);
          const data = res.data.data;
          db.collection("paths").doc("quiz_attempts").set({ data });
        }
      }
    }
  };
  useEffect(() => {
    db.collection("paths")
      .doc("quiz_attempts")
      .get()
      .then((data) => {
        setAttempts(data.data);
      });
  }, []);

  useEffect(() => {
    GetQuizzes();
  }, []);
  // console.log(attempts);

  const GetQuestions = async ({ test_id, user_id }) => {
    const data = new FormData();
    data.append("test_code", test_id);
    data.append("user_id", user_id);
    const res = await axios.post(
      ENDPOINT + "get-quiz-attempts.php",
      data,
      SecurityHeaders,
    );

    if (res) {
      if (res.data) {
        if (res.data.status === "success") {
          setQuestions(res.data.data);
        }
      }
    }
  };
  return (
    <div>
      <Accordion>
        {attempts ? (
          <>
            {attempts.map((item, index) => (
              <Accordion.Item
                key={index}
                eventKey={index}
                onClick={() =>
                  GetQuestions({ test_id: item.test_id, user_id: item.user_id })
                }
                className="mt-3"
              >
                <Accordion.Header className="d-flex align-items-center  justify-content-between">
                  <div className="d-flex align-items-center">
                    <div>
                      <Avatar alt="Remy Sharp" src={item.profile_pic} />
                    </div>
                    <div className="ms-2">
                      {item.first_name + " " + item.last_name}
                    </div>
                  </div>
                  <div className="ms-3">{item.start_time}</div>
                  <div className="ms-3">{item.Course_url}</div>
                  <div className="ms-3">{item.title}</div>
                </Accordion.Header>
                <Accordion.Body>
                  {questions ? (
                    <>
                      {questions.map((item) => (
                        <>
                          <h6>
                            {questions.indexOf(item.quiz_id) + 1}.{" "}
                            {item.question}
                          </h6>

                          <div className="question-options mt-4  min-vh-50 col-lg-8 col-md-8 col-sm-12 col-12 p-0">
                            {item.option1 ? (
                              <>
                                <div className="option-item mb-3">
                                  <button
                                    disabled={true}
                                    className={
                                      item.answer === "option1"
                                        ? " btn w-100 btn-outline-success"
                                        : "btn w-100  btn-outline-dark"
                                    }
                                  >
                                    {item.option1}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {item.option2 ? (
                              <>
                                <div className="option-item mb-3">
                                  <button
                                    disabled={true}
                                    className={
                                      item.answer === "option2"
                                        ? " btn w-100 btn-outline-success"
                                        : "btn w-100  btn-outline-dark"
                                    }
                                  >
                                    {item.option2}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {item.option3 ? (
                              <>
                                <div className="option-item mb-3">
                                  <button
                                    disabled={true}
                                    className={
                                      item.answer === "option3"
                                        ? "btn w-100 btn-outline-success"
                                        : item.correct === 1
                                        ? "btn w-100 btn-outline-success"
                                        : "btn w-100 btn-outline-danger"
                                    }
                                  >
                                    {item.option3}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {item.option4 ? (
                              <>
                                <div className="option-item mb-3">
                                  <button
                                    disabled={true}
                                    className={
                                      item.answer === "option4"
                                        ? " btn w-100 btn-outline-success"
                                        : "btn w-100  btn-outline-dark"
                                    }
                                  >
                                    {item.option4}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  ) : null}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </>
        ) : null}
      </Accordion>
    </div>
  );
};

export default QuizAttemptHistory;
