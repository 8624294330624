import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PlacementSupport = () => {
  // Initial data for Initiate, UnResolved, Closed
  const initialSeries = [50, 30, 20];
  const [series, setSeries] = useState(initialSeries);

  const options = {
    chart: {
      type: 'donut',
      width: 400,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    labels: ['Initiate', 'UnResolved', 'Closed'],
    colors: ['#1E90FF', '#FFA07A', '#FF6347'],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${Math.round(val)}%`,
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        colors: ['#333'],
      },
    },
    title: {
      text: 'Placement Support Status',
      align: 'center',
      margin: 20,
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: ['#333'],
        useSeriesColors: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  // Interactive buttons for the chart
  const appendData = () => {
    setSeries((prevSeries) => [...prevSeries, Math.floor(Math.random() * 20) + 1]);
  };

  const removeData = () => {
    setSeries((prevSeries) => (prevSeries.length > 1 ? prevSeries.slice(0, -1) : prevSeries));
  };

  const randomize = () => {
    setSeries(series.map(() => Math.floor(Math.random() * 100) + 1));
  };

  const reset = () => {
    setSeries(initialSeries);
  };

  return (
    <div style={styles.container}>
      
      <div style={styles.card}>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width={445}
        />
        
      </div>
    </div>
  );
};

// Shared styles with the CustomerSupport component
const styles = {
 
  card: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '450px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border:'1px solid gray',
  },
 
  
  title: {
    fontSize: '24px',
    fontWeight: '600',
    color: 'black',
    marginBottom: '15px',
  },
};

export default PlacementSupport;
