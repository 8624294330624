import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useActionsContext } from "../context/ActionsContext";
import { Close } from "@material-ui/icons";
import { AuthContext } from "../context/AuthContext";
import HomeIcon from "@mui/icons-material/Home";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined";
import TurnedInNotOutlinedIcon from "@mui/icons-material/TurnedInNotOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import BatchPredictionOutlinedIcon from "@mui/icons-material/BatchPredictionOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import EnergySavingsLeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MarkAsUnreadOutlinedIcon from "@mui/icons-material/MarkAsUnreadOutlined";
import CommentBankOutlinedIcon from "@mui/icons-material/CommentBankOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import PhotoCameraFrontOutlinedIcon from "@mui/icons-material/PhotoCameraFrontOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import VideoCameraFrontOutlinedIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import PlayLessonOutlinedIcon from "@mui/icons-material/PlayLessonOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import DiscountIcon from "@mui/icons-material/Discount";
import GridViewIcon from "@mui/icons-material/GridView";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Avatar from "@mui/material/Avatar";

const Sidebar = ({ account }) => {
  const { permission } = useActionsContext();

  const { user } = useContext(AuthContext);

  const { togglesidebar, settogglesidebar } = useActionsContext();
  return (
    <>
      {permission && account ? (
        <>
          <div id="scrollbar">
            <div class="container-fluid vh-90">
              <div id="two-column-menu"></div>
              <ul class="navbar-nav" id="navbar-nav">
                <div className="d-flex">
                  <div className="sidebar-close click">
                    <Close
                      className="text-light"
                      onClick={() => settogglesidebar(false)}
                    />
                  </div>
                </div>

                {permission ? (
                  <>
                    <li class="nav-item">
                      <Link to="/" class="nav-link menu-link">
                        <i class="ri-home-3-line"></i>
                        <span data-key="t-classes">Home</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                <li class="nav-item">
                  <Link to="/recent-recordings" class="nav-link">
                    <VideoLibraryOutlinedIcon />{" "}
                    <span data-key="t-classes">Recent Recordings</span>
                  </Link>
                </li>

                {parseInt(permission.upcoming_classes) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/classes" class="nav-link">
                        <ClassOutlinedIcon />{" "}
                        <span data-key="t-classes">Upcoming Classes</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.active_batches) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/active-batches" class="nav-link">
                        <NotificationsOutlinedIcon />{" "}
                        <span data-key="t-active-batches">Active Batches</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.customer_support) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/customer-support" class="nav-link menu-link">
                        <SupportAgentOutlinedIcon />{" "}
                        <span data-key="t-customer-support">
                          Customer Support
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.doubt_support) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/students-support" class="nav-link">
                        <LiveHelpOutlinedIcon />{" "}
                        <span data-key="t-main-support">Doubt Support</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.course_registrations) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/course-registrations" class="nav-link ">
                        <AppRegistrationIcon />{" "}
                        <span data-key="t-trainings">Course Registrations</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.employees) ||
                parseInt(permission.team_management) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/team" class="nav-link">
                        <GroupAddIcon />{" "}
                        <span data-key="t-employees">Employees</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.dashboards) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebarDashboard"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarDashboard"
                        data-key="t-dashboard"
                      >
                        <GridViewIcon /> Dashboards
                      </a>
                      <div class="collapse menu-dropdown" id="sidebarDashboard">
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.hr_dashboard) ? (
                            <>
                              <li class="nav-item">
                                <Link to="/hr-dashboard" class="nav-link ">
                                  <PersonOutlineOutlinedIcon />
                                  <span data-key="t-dashboard">
                                    Hr Dashboard
                                  </span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {parseInt(permission.marketing_manager_dashboard) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/marketing-dashboard"
                                  class="nav-link"
                                >
                                  <AddBusinessOutlinedIcon />
                                  <span data-key="t-dashboard">
                                    Marketing Dashboard
                                  </span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.class_manager_dashboard) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/class-dashboard"
                                  class="nav-link"
                                  data-key="t-dashboard"
                                >
                                  <SupervisorAccountOutlinedIcon />
                                  Class Manager Dashboard
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {parseInt(permission.bde_dashboard) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/customer-dashboard"
                                  class="nav-link"
                                  data-key="t-dashboard"
                                >
                                  <ContactPhoneOutlinedIcon />
                                  Customers Manager Dashboard
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.placement_officer_dashboard) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/placement-dashboard"
                                  class="nav-link"
                                  data-key="t-dashboard"
                                >
                                  <TouchAppOutlinedIcon />
                                  Placements Manager Dashboard
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.finance_manager_dashboard) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/finance-dashboard"
                                  class="nav-link"
                                  data-key="t-dashboard"
                                >
                                  <PaymentsOutlinedIcon />
                                  Finance Manager Dashboard
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.students_management) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebarstudents"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarstudents"
                        data-key="t-students"
                      >
                        <SchoolOutlinedIcon />
                        Student Management
                      </a>
                      <div class="collapse menu-dropdown" id="sidebarstudents">
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.students_list) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/students"
                                  class="nav-link"
                                  data-key="t-main-students"
                                >
                                  <ListAltOutlinedIcon />
                                  Students List
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

{parseInt(permission.students_list) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/placement-cell-leaderboard"
                                  class="nav-link"
                                  data-key="t-main-students"
                                >
                                  <ListAltOutlinedIcon />
                                 Placement Cell leaderboard
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.doubt_support) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/students-support"
                                  class="nav-link"
                                  data-key="t-main-students"
                                >
                                  <NotListedLocationOutlinedIcon />
                                  Doubts
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.assignment_submissions) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/assignment-submissions"
                                  class="nav-link"
                                  data-key="t-main-students"
                                >
                                  <AssignmentIndOutlinedIcon />
                                  Assignment Submissions
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.project_submissions) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/project-submissions"
                                  class="nav-link"
                                  data-key="t-main-students"
                                >
                                  <BackupOutlinedIcon />
                                  Project Submissions
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.placement_support) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/placement-support"
                                  class="nav-link"
                                  data-key="t-main-students"
                                >
                                  <RecommendOutlinedIcon />
                                  Placement Support
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.courses_management) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebartrainings"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebartrainings"
                        data-key="t-trainings"
                      >
                        <CastForEducationOutlinedIcon />
                        Courses Management
                      </a>

                      <div class="collapse menu-dropdown" id="sidebartrainings">
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.course_registrations) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/course-registrations"
                                  class="nav-link "
                                >
                                  <AppRegistrationIcon />
                                  <span data-key="t-trainings">
                                    Course Registrations
                                  </span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {parseInt(permission.courses) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/courses"
                                  class="nav-link"
                                  data-key="t-trainings"
                                >
                                  <LibraryBooksOutlinedIcon />
                                  Courses
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.active_batches) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/active-batches"
                                  class="nav-link"
                                  data-key="t-trainings"
                                >
                                  <NotificationsOutlinedIcon />
                                  Active Batches
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {parseInt(permission.all_batches) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/batches"
                                  class="nav-link"
                                  data-key="t-trainings"
                                >
                                  <BatchPredictionOutlinedIcon />
                                  All Batches
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.upcoming_classes) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/classes"
                                  class="nav-link"
                                  data-key="t-trainings"
                                >
                                  <ClassOutlinedIcon />
                                  Upcoming Classes
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.all_class_schedules) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/classes"
                                  class="nav-link"
                                  data-key="t-trainings"
                                >
                                  <UpdateOutlinedIcon />
                                  All Class Schedules
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {parseInt(permission.recordings) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/recordings"
                                  class="nav-link"
                                  data-key="t-trainings"
                                >
                                  <SlideshowOutlinedIcon />
                                  Recordings
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.fee_payments) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/payments"
                                  class="nav-link"
                                  data-key="t-trainings"
                                >
                                  <AssuredWorkloadOutlinedIcon />
                                  Fee Payments
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.team_management) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebarteam"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarteam"
                        data-key="t-team"
                      >
                        <PeopleIcon />
                        Team Management
                      </a>
                      <div class="collapse menu-dropdown" id="sidebarteam">
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.team_list) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/team"
                                  class="nav-link"
                                  data-key="t-main-team"
                                >
                                  <PlaylistAddOutlinedIcon />
                                  Team List
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.tasks) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/team-task-submissions"
                                  class="nav-link"
                                  data-key="t-main-team"
                                >
                                  <PlaylistAddCheckOutlinedIcon />
                                  Tasks
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.meetings) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/team-meetings"
                                  class="nav-link"
                                  data-key="t-main-team"
                                >
                                  <Groups3OutlinedIcon />
                                  Meetings
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.leaves) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/team-leaves"
                                  class="nav-link"
                                  data-key="t-main-team"
                                >
                                  <PostAddIcon />
                                  Leaves
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {parseInt(permission.leaves) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/team-members-all"
                                  class="nav-link"
                                  data-key="t-main-team"
                                >
                                  <PeopleIcon />
                                  Team Members
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.community_management) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebarcommunity"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarcommunity"
                        data-key="t-community"
                      >
                        <GroupsOutlinedIcon />
                        Community Management
                      </a>
                      <div class="collapse menu-dropdown" id="sidebarcommunity">
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.community_management) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/teams-groups"
                                  class="nav-link"
                                  data-key="t-main-community"
                                >
                                  <PeopleOutlineIcon />
                                  Teams Groups
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.posts) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/posts"
                                  class="nav-link"
                                  data-key="t-main-community"
                                >
                                  <MarkAsUnreadOutlinedIcon />
                                  Posts
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.comments) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/comments"
                                  class="nav-link"
                                  data-key="t-main-community"
                                >
                                  <CommentBankOutlinedIcon />
                                  Comments
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.messages) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/messages"
                                  class="nav-link"
                                  data-key="t-main-community"
                                >
                                  <CommentOutlinedIcon />
                                  Messages
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.student_support) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebarsupport"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarsupport"
                        data-key="t-support"
                      >
                        <SwitchAccountOutlinedIcon />
                        Student Support
                      </a>
                      <div class="collapse menu-dropdown" id="sidebarsupport">
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.doubt_support) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/students-support"
                                  class="nav-link"
                                  data-key="t-main-support"
                                >
                                  <LiveHelpOutlinedIcon />
                                  Doubt Support
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.placement_support) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/placement-support"
                                  class="nav-link"
                                  data-key="t-main-support"
                                >
                                  <RecommendOutlinedIcon />
                                  Placement Support
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.internships_management) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebarinternships"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarintenrships"
                        data-key="t-internships"
                      >
                        <PhotoCameraFrontOutlinedIcon />
                        Internships Management
                      </a>

                      <div
                        class="collapse menu-dropdown"
                        id="sidebarinternships"
                      >
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.intenships) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/internships"
                                  class="nav-link"
                                  data-key="t-main-internships"
                                >
                                  <WorkOutlineOutlinedIcon />
                                  Internships
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.intenship_applications) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/internship-applications"
                                  class="nav-link"
                                  data-key="t-main-internships"
                                >
                                  <SettingsApplicationsOutlinedIcon />
                                  Internship Applications
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.videos_management) ? (
                  <>
                    <li class="nav-item">
                      <a
                        href="#sidebarvideos"
                        class="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarvideos"
                        data-key="t-videos"
                      >
                        <VideoCameraFrontOutlinedIcon />
                        Videos
                      </a>
                      <div class="collapse menu-dropdown" id="sidebarvideos">
                        <ul class="nav nav-sm flex-column">
                          {parseInt(permission.tutorials) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/videos"
                                  class="nav-link"
                                  data-key="t-main-videos"
                                >
                                  <SlideshowOutlinedIcon />
                                  Tutorials
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {parseInt(permission.shorts) ? (
                            <>
                              <li class="nav-item">
                                <Link
                                  to="/shorts"
                                  class="nav-link"
                                  data-key="t-main-videos"
                                >
                                  <PlayLessonOutlinedIcon />
                                  Shorts
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {parseInt(permission.get_images) ? (
                  <>
                  <li class="nav-item">
                  <a
                    href="#sidebarimages"
                    class="nav-link"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarimages"
                    data-key="t-images"
                  >
                    Images
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarimages">
                    <ul class="nav nav-sm flex-column">
                      {parseInt(permission.upload_image) ? (
                        <>
                        <li class="nav-item">
                        <Link
                          to="/get-images"
                          class="nav-link"
                          data-key="t-main-images"
                        >
                          Upload Images
                        </Link>
                      </li>
                        </>
                      ) : (
                        <></>
                      )}
                      
                    </ul>
                  </div>
                </li>
                  </>
                ) : (
                  <></>
                )}
                
                {parseInt(permission.screen_shots) ? (
                  <>
                    <li class="nav-item">
                      <Link
                        to="/payment-screen-shots"
                        class="nav-link menu-link"
                      >
                        <LocalAtmOutlinedIcon />{" "}
                        <span data-key="t-customer-support">
                          Payment Screen Shots
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.leads) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/leads" class="nav-link menu-link">
                        <i class="ri-honour-line"></i>{" "}
                        <span data-key="t-leads">Leads</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {parseInt(permission.users) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/placement_students" class="nav-link menu-link">
                        <AccountCircleOutlinedIcon />{" "}
                        <span data-key="t-users">Placements</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {parseInt(permission.users) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/users" class="nav-link menu-link">
                        <AccountCircleOutlinedIcon />{" "}
                        <span data-key="t-users">Users</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.payments) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/payments" class="nav-link menu-link">
                        <AssuredWorkloadOutlinedIcon />{" "}
                        <span data-key="t-payments">Payments</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {parseInt(permission.upcoming_classes) ? (
                  <>
                    <li class="nav-item">
                      <Link
                        to="/quiz-attempts"
                        class="nav-link"
                        data-key="t-classes"
                      >
                        <QuizOutlinedIcon />
                        Quiz Attempts
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {/* {parseInt(permission.upcoming_classes) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/college-registrations" class="nav-link" data-key="t-classes">
                                                College Registrations
                                            </Link>
                                        </li>

                                    </> :
                                    <>

                                    </>} */}

                {parseInt(permission.daily_reports) ? (
                  <>
                    <li class="nav-item">
                      <Link to="/daily-reports" class="nav-link menu-link">
                        <ArticleIcon />{" "}
                        <span data-key="t-reports">Daily Reports</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {/* 
                                {parseInt(permission.security) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/security" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-security">Security</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>} */}

                {parseInt(permission.coupen_codes) ? (
                  <>
                    <li className="nav-item">
                      <Link to="/coupon-codes" className="nav-link menu-link">
                        <DiscountIcon />{" "}
                        <span data-key="t-coupons">Coupon Codes</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {/* {parseInt(permission.withdrawls) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/withdrawls" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-withdrawls">Withdrawls</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}

                {parseInt(permission.certificate_management) ? (
                  <>
                    <li className="nav-item">
                      <Link to="/certificates" className="nav-link menu-link">
                        <DocumentScannerOutlinedIcon />{" "}
                        <span data-key="t-certificates">
                          Certificate Management
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {/* 
                                {parseInt(permission.sign_in) ?
                                    <>


                                        <li className="nav-item">
                                            <Link to="/signin" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-signin">Sign In</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}

                {/* {parseInt(permission.create_invoice) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/create-invoice" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-invoice">Create Invoice</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}

                {/* 
                                {parseInt(permission.invoices_list) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/invoices-list" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-invoiceslist">Invoices List</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}

                {/* {parseInt(permission.project_overview) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/project-overview" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-project-overview">Project Overview</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}

                {parseInt(permission.course_registrations) ? (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/course-registrations"
                        className="nav-link menu-link"
                      >
                        <AppRegistrationIcon />{" "}
                        <span data-key="t-registrations-couse">
                          Course Registrations
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {/* {parseInt(permission.user_lists) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/users-list" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-users">Users List</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}
 */}

                {/* {parseInt(permission.analytics) ?
                                    <>

                                        <li className="nav-item">
                                            <Link to="/analytics" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-analytics">Analytics</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}

                {/* {parseInt(permission.projects_dashboard) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/projects-dashboard" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-projects">Projects Dashboard</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}

                {parseInt(permission.workshop_registrations) ? (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/workshop-registrations"
                        className="nav-link menu-link"
                      >
                        <HomeRepairServiceOutlinedIcon />
                        <span data-key="t-workshops">
                          Workshop Registrations
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {/* {parseInt(permission.campus_ambassadors) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/campus-ambassadors" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-ambassadors">Campus Ambassadors</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}

                {/* {parseInt(permission.job_hunt) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/job-hunt" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-job">Job Hunt</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}

                {/* {parseInt(permission.hacking_workshop_leads) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/hacking-workshop-registrations" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i>
                                                <span data-key="t-workshop-regs">CEH Workshop Leads</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>} */}

                {parseInt(permission.hacking_internship_leads) ? (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/hacking-internship-registrations"
                        className="nav-link menu-link"
                      >
                        <i className="ri-honour-line"></i>
                        <span data-key="t-internship-leads">
                          CEH Internship Leads
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {/* {parseInt(permission.wallet) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/wallet" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-wallet">Wallet</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}

                {parseInt(permission.super_admin_dashboard) ? (
                  <>
                    <li className="nav-item">
                      <Link to="/" className="nav-link menu-link">
                        <i className="ri-honour-line"></i>{" "}
                        <span data-key="t-super-admin">
                          Super Admin Dashboard
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sidebar;
