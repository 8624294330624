import React from 'react'

import Rating from '@mui/material/Rating';
import { Avatar, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import DetectDevice from '../../context/DetectDevice';

 
  
const CandidateItem = ({item}) => {

const device = DetectDevice()
console.log(device)



  return (
    <>
    
    {device==="mobile"?
    <>
        <div className="card mb-0 p-2">       
              <div className="card-body">
                  
                  <div className="d-lg-flex align-items-center">
                    
                  
                    <div className=" mb-2 my-lg-0">
                      
                     <div className="d-flex">
                     <Link to="/students/" className='d-flex'>
                      <Avatar className='me-2 ' src={item.profile_pic}/>
                      <div>
                        <h5 className="fs-16 m-0">{item.first_name+" "+item.last_name}</h5>
                        <div>
                        <span className="badge bg-warning text-light">
                            Good Match
                          </span>
                        </div>
                      </div>
                      </Link>
                     </div>


                      <div className="d-flex align-items-center">
                      <div>
                      <p className="text-muted mb-0 me-3">{item.title}</p>
                      </div>
                      </div>

                      <div className="d-flex">


                      </div>

                    </div>
                      
                      <div className='d-flex justify-content-between align-items-center'>
                        <div>
                          <i className="ri-time-line text-primary me-1 align-bottomr" />
                          <span className="badge bg-danger-subtle text-danger">
                            Part Time
                          </span>
                        </div>
                        <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-lg-0">
                      
                          <div className="badge text-bg-success">
                            
                            <i className="mdi mdi-star me-1" />
                            4.2
                          </div>
                          <div className="text-muted">2.2k Ratings</div>
                        </div>
                        <div>
                          <a href="#!" className="btn btn-ghost-danger btn-icon custom-toggle mt-0" data-bs-toggle="button" aria-pressed="false">
                          
                            <span className="icon-on">
                              <i className="ri-bookmark-line align-bottom" />
                            </span>
                            <span className="icon-off">
                              <i className="ri-bookmark-3-fill align-bottom" />
                            </span>
                          </a>
                        </div>
                      </div>

                  </div>

                  <div className="my-2">
                <div >
                    <h6>COVER LETTER</h6>
                </div>
                <div className='ps-3'>
                {item.reason}
                </div>

                </div>

                <div className="d-flex">

                </div>
                <div className='navbar'>
                  <div className='d-flex mb-0'>
                    <p className='mb-0'>Applied 4 days ago</p>
                  </div>
                  <div>
                  <a href="#!" className="btn btn-soft-success mt-2">
                    View Details
                  </a>
                  </div>
                </div>
                </div>
                
              </div>
    </>:
    
    <>
      <div className="card mb-0 p-2">
               <div className="d-flex justify-content-between">

               <div className="d-flex">
            <Chip size='small' className='d-flex bg-warning align-items-center' label={<>
            
               <div className="d-flex align-items-center">
              
                      <h6 className='m-0 text-light'>Good Match</h6>
               </div>
            </>}/>
                </div>
                



                <p>Applied 4 days ago</p>
               </div>
                <hr className='my-1'></hr>
                
                <div className="card-body">
                  
                  <div className="d-lg-flex align-items-center">
                    
                  
                    <div className=" mb-2 my-lg-0">
                      
                     <div className="d-flex ">
                     <Link to="/students/" className='d-flex align-items-center'>
                      <Avatar className='me-2 ' src={item.profile_pic}/>
                    <div>
                    <h5 className="fs-16 mb-2">{item.first_name+" "+item.last_name}</h5>
                    {item.internship_id+"-"+item.id}
                    </div>
                      </Link>
                     </div>


                      <div className="d-flex align-items-center">

                      <p className="text-muted mb-0 me-3">{item.title}</p>
   
                      <div>
                        <i className="ri-map-pin-2-line text-muted me-1 align-bottom" />
                 
                      </div>
                      </div>










                      <div className="d-flex">


                      </div>






                    </div>
                    <div className="d-flex gap-4 mt-0 text-muted mx-auto">
                      
                     
                      <div>
                        <i className="ri-time-line text-primary me-1 align-bottom" />
                        <span className="badge bg-danger-subtle text-danger">
                          Part Time
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-3 my-lg-0">
                      
                      <div className="badge text-bg-success">
                        
                        <i className="mdi mdi-star me-1" />
                        4.2
                      </div>
                      <div className="text-muted">2.2k Ratings</div>
                    </div>
                    <div>
                      
                      <a href="#!" className="btn btn-soft-success">
                        View Details
                      </a>
                      <a
                        href="#!"
                        className="btn btn-ghost-danger btn-icon custom-toggle"
                        data-bs-toggle="button"
                        aria-pressed="false"
                      >
                        
                        <span className="icon-on">
                          <i className="ri-bookmark-line align-bottom" />
                        </span>
                        <span className="icon-off">
                          <i className="ri-bookmark-3-fill align-bottom" />
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className="d-flex my-2">
                <div className="col-2">
                    <h6>COVER LETTER</h6>
                </div>
                <div className="col-10">
                {item.reason}
                </div>

                </div>

                                  <div className="d-flex my-2">
                <div className="col-2">
                    <h6>EXPERIENCE</h6>
                </div>
                <div className="col-10">
                Bachelor of Science (B.Sc), Electronics and Instrumentation (2021 - 2024)
                </div>

                </div>

                <div className="d-flex my-2">
                <div className="col-2">
                    <h6>EDUCATION</h6>
                </div>
                <div className="col-10">
                Bachelor of Science (B.Sc), Electronics and Instrumentation (2021 - 2024)
                </div>

                </div>



                <div className="d-flex my-2">
                <div className="col-2">
                    <h6>SKILLS</h6>
                </div>
                <div className="col-10">

                <div className="d-flex">
                <div className="d-flex me-3 align-items-center">
                <Rating/>
                <h6>HTML</h6>
                </div>


                <div className="d-flex me-2 align-items-center">
                <Rating/>
                <h6>HTML</h6>
                </div>
                </div>
                
                </div>

                </div>


                <div className="d-flex">

                </div>


                </div>
              </div>
    </>}
            
    </>
  )
}

export default CandidateItem