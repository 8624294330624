import React, { useContext, useEffect, useState } from "react";
import DemoData from "../../data/DemoData";
import CandidateItem from "./CandidateItem";
import Dropdown from "react-bootstrap/Dropdown";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../context/EndPoint";
import axios from "axios";
import PlacementRecommendationItem from "./PlacementRecommendationItem";

const StudentsRankings = () => {
  const { user } = useContext(AuthContext);
  const [internships, setinternships] = useState(null);
  const [applications, setApplications] = useState(null);
  
  const GetInternships = async (status) => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    formData.append("status", "all");

    const res = await axios.post(
      ENDPOINT + "student-placement-ranks.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      },
    );
    if (res) {
      if (res.data.status === "success") {
        setinternships(res.data.data);
        setApplications(res.data);
      } else {
      }
    }
    // console.log(applications?applications.total_applications:null)
  };

  useEffect(() => {
    GetInternships();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row gy-2 mb-2" id="candidate-list">
          {internships ? (
            <>
              {internships.map((item) => (
                <>
                  <div className="col-md-6 col-lg-12">
                    <PlacementRecommendationItem item={item} />
                  </div>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        {/* end row */}
        <div
          className="row g-0 justify-content-end mb-4"
          id="pagination-element"
        >
          {/* end col */}
          <div className="col-sm-6">
            <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
              <div className="page-item disabled">
                <a
                  href="javascript:void(0);"
                  className="page-link"
                  id="page-prev"
                >
                  Previous
                </a>
              </div>
              <span id="page-num" className="pagination">
                <div className="page-item active">
                  <a
                    className="page-link clickPageNumber"
                    href="javascript:void(0);"
                  >
                    1
                  </a>
                </div>
                <div className="page-item">
                  <a
                    className="page-link clickPageNumber"
                    href="javascript:void(0);"
                  >
                    2
                  </a>
                </div>
                <div className="page-item">
                  <a
                    className="page-link clickPageNumber"
                    href="javascript:void(0);"
                  >
                    3
                  </a>
                </div>
              </span>
              <div className="page-item">
                <a
                  href="javascript:void(0);"
                  className="page-link"
                  id="page-next"
                >
                  Next
                </a>
              </div>
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end row */}
      </div>
    </>
  );
};

export default StudentsRankings;
