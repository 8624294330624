import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AssignmentSubmission = () => {
  const series = [320, 180]; // Example data: 320 for "Submitted" and 180 for "Evaluated"

  const options = {
    chart: {
      type: 'pie',
      width: 380, // Set the initial width
    },
    labels: ["Assignments Submitted", "Assignments Evaluated"],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        opacity: 0.3,
      },
      offsetY: -10, // Bring the labels closer to the center of the pie
      formatter: (value, opts) => {
        return `${opts.w.globals.series[opts.seriesIndex]} assignments`; // Only show assignment counts
      },
    },
    legend: {
      position: 'bottom', // Position the legend below the chart
      markers: {
        width: 12,
        height: 12,
      },
      fontSize: '14px',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300, // Adjust chart size on smaller screens
        },
        legend: {
          position: 'bottom',
          fontSize: '12px',
        },
      },
    }],
    tooltip: {
      y: {
        formatter: (value) => `${value} assignments`, // Tooltip shows assignment count
      },
    },
    colors: ['#4CAF50', '#FF9800'], // Custom colors for clarity
  };

  return (
    <>

      <div id="chart">
        <ReactApexChart options={options} series={series} type="pie" height={270} />
      </div>
    </>
  );
};

export default AssignmentSubmission;
