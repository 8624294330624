import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const CourseRegistrations = () => {
    const[series,setSeries] = useState({
        series: [{
            name: 'Ethical-hacking-online',
            data: [44, 55, 41, 67, 22, 43,44, 55, 41, 67, 22, 22]
          }, {
            name: 'Ethical-hacking-offline',
            data: [13, 23, 20, 8, 13, 27,13, 23, 20, 8, 13, 29]
          }, {
            name: 'Full-stack-development-online',
            data: [11, 17, 15, 15, 21, 14, 11, 17, 15, 15, 21, 19]
          }, {
            name: 'Full-stack-development-offline',
            data: [21, 7, 25, 13, 22, 8, 21, 7, 25, 13, 22, 9]
          }],
          options: {
            title: {
              text: 'Course Registrations',
              align: 'center',
              margin: 20,
              style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#333',
              },
            },
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: 0,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: '9px',
                      fontWeight: 900
                    }
                  }
                }
              },
            },
            xaxis: {
              type: 'datetime',
              categories: ['01/01/2024 GMT', '02/01/2024 GMT', '03/01/2024 GMT', '04/01/2024 GMT',
                '05/01/2024 GMT', '06/01/2024 GMT','07/01/2024',,'08/01/2024','09/01/2024','10/01/2024','11/01/2024','12/01/2024'          ],
            },
            legend: {
              position: 'bottom',
              offsetX: 40
            },
            fill: {
              opacity: 1
            }
          },
        
        
        
    })
    
  return (
    <div className=' rounded position-y-fixed '>
    <div className='p-3 bg-gray rounded ' style={styles.bord}>
   
  <ReactApexChart options={series.options} series={series.series} type="bar" height={350} />
  </div>

    </div>
  )
}
const styles = {
  bord:{
    border:'1px solid gray',
  }
}

export default CourseRegistrations