import React from 'react';
import ReactApexChart from 'react-apexcharts';

const UsersChart = () => {
  const [options] = React.useState({
    chart: {
      id: 'basic-area-chart',
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    },
  });

  const [series] = React.useState([
    {
      name: 'Users',
      data: [10, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ]);

  return (
    <div className='position-y-fixed'>
     <center><h3 className='m-4 text-dark'>Users</h3></center> 
    <ReactApexChart options={options} series={series} type="area" height={380} />
    </div>
  );
};

export default UsersChart;
