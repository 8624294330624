import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const CustomerSupport = () => {
  const initialSeries = [70, 20, 10]; // Sample data: Chat Initiated, Unresolved, Closed
  
  const [series, setSeries] = useState(initialSeries);

  const options = {
    chart: {
      type: 'donut',
      width: 400,
    },
    labels: ['Chat Initiated', 'Unresolved', 'Closed'],
    colors: ['#4CAF50', '#FF9800', '#F44336'],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${Math.round(val)}%`, // Display percentages
      style: {
        colors: ['#333'], // Label color
        fontSize: '16px',
      },
    },
    title: {
      text: 'Customer Support Status',
      align: 'center',
      margin: 20,
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      position: 'bottom',
      labels: {
        colors: ['#333'],
        useSeriesColors: true,
      },
    },
  };

  const appendData = () => {
    setSeries((prevSeries) => [...prevSeries, Math.floor(Math.random() * 20) + 1]);
  };

  const removeData = () => {
    setSeries((prevSeries) => (prevSeries.length > 1 ? prevSeries.slice(0, -1) : prevSeries));
  };

  const randomize = () => {
    setSeries(series.map(() => Math.floor(Math.random() * 100) + 1));
  };

  const reset = () => {
    setSeries(initialSeries);
  };

  return (
    <div style={styles.container}>
       
      
      <div style={styles.card}>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width={450}
        />
        
        
      </div>
    </div>
  );
};

// Enhanced styles for layout and appearance
const styles = {
 
  
  card: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '12px',
    // boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '450px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border:"1px solid gray"
  },

container:{

},

  title: {
    fontSize: '24px',
    fontWeight: '600',
    color: 'black',
    marginBottom: '15px',
  },
};


export default CustomerSupport;
