import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../context/EndPoint";
import { useParams } from "react-router";
import { Avatar } from "@mui/material";

const PlacementItem = ({ item }) => {
  const { user } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  const params = useParams();
  console.log(params.id);

  const getUserData = async () => {
    const formData = new FormData();

    formData.append("session", user.SessionId);
    formData.append("user_id", params.id);
    formData.append("query", 1);
    try {
      const res = await axios.post(
        "https://internalapi.skillsuprise.com/single-student-placement.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (res.data.status === "success") {
        setUserData(res.data.data);
        console.log(res.data.data.user_technical_skills[0].title);
      } else {
        setError("No data found or unauthorized access");
      }
    } catch (err) {
      setError("Error fetching data: " + err.message);
    }
  };

  // Fetch user data on component mount
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      {userData ? (
        <>
          <div className="container-fluid">
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div
                className="profile-wid-bg d-sm-block d-block d-md-none"
                style={{ height: "25vh" }}
              >
                <img
                  src="assets/images/profile-bg.jpg"
                  alt=""
                  className="profile-wid-img"
                />
              </div>
              <div className="profile-wid-bg d-sm-none d-none d-lg-block d-md-block">
                <img
                  src="assets/images/profile-bg.jpg"
                  alt=""
                  className="profile-wid-img"
                />
              </div>
            </div>
            <div className="pt-4 mb-md-3 mb-sm-2 mb-lg-3 pb-lg-4 profile-wrapper">
              <div className="row g-4">
                <div className="col-auto">
                  <div className="avatar-lg">
                    <img
                      src={userData.profile_pic ? userData.profile_pic : null}
                      alt="user-img"
                      className="img-thumbnail rounded-circle"
                    />
                  </div>
                </div>
                {/*end col*/}
                <div className="col">
                  <div className="p-2">
                    <h3 className="text-white mb-1 fs-16 d-lg-none d-md-none">
                      {userData.first_name + " " + userData.last_name}
                    </h3>
                    <p className="text-white text-opacity-75 mb-1 d-lg-none d-md-none fs-25">
                      {userData.course_url}
                    </p>
                    <div>
                      <span className="badge bg-warning text-light">
                        Good Match
                      </span>
                    </div>
                    <div className="hstack text-white-50 gap-1 d-lg-none d-md-none">
                      <div className="me-2 ">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        Batch :{userData.user_id}
                      </div>

                      <div className="me-2 ">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        StudentID :{userData.student_id}
                      </div>
                    </div>

                    <h3 className="text-white mb-1 d-sm-none d-xs-none d-lg-block d-md-block d-none">
                      {userData.first_name + " " + userData.last_name}
                    </h3>
                    <p className="text-white text-opacity-75 mb-1 d-sm-none d-xs-none d-lg-block d-md-block d-none">
                      {userData.course_url}
                    </p>
                    <div className="hstack text-white-50 gap-1 d-sm-none d-xs-none d-lg-block d-md-block d-none">
                      <div className="me-2">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        Batch :{userData.user_id}
                      </div>

                      <div className="me-2">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        StudentID :{userData.student_id}
                      </div>
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-12 col-lg-auto order-last order-lg-0"></div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>

            <div className="col-lg-12 p-3 border rounded">
              <div className="card">
                <div className="card-body">
                  <div>
                    <h5 className="card-title">Student Placement Details</h5>

                    <ul className="list-group">
                      <div className="row">
                      <div className="col-lg-4">
                        <li className="list-group-item">
                          <strong>Technical Proficiency: </strong>
                          {userData.technical_proficiency}
                        </li>
                        <li className="list-group-item">
                          <strong>AI Awareness: </strong>
                          {userData.ai_awareness}
                        </li>
                        <li className="list-group-item">
                          <strong>Problem Solving: </strong>
                          {userData.problem_solving}
                        </li>
                        <li className="list-group-item">
                          <strong>Soft Skills: </strong>
                          {userData.soft_skills}
                        </li>
                        <li className="list-group-item">
                          <strong>Adaptability: </strong>
                          {userData.adaptability}
                        </li>
                        <li className="list-group-item">
                          <strong>Continuous Learning: </strong>
                          {userData.continuous_learning}
                        </li>
                        <li className="list-group-item">
                          <strong>Innovation: </strong>
                          {userData.innovation}
                        </li>
                        <li className="list-group-item">
                          <strong>Data Literacy: </strong>
                          {userData.data_literacy}
                        </li>
                        <li className="list-group-item">
                          <strong>Networking: </strong>
                          {userData.networking}
                        </li>
                        <li className="list-group-item">
                          <strong>Personal Branding: </strong>
                          {userData.personal_branding}
                        </li>
                        <li className="list-group-item">
                          <strong>Cultural Awareness: </strong>
                          {userData.cultural_awareness}
                        </li>
                        <li className="list-group-item">
                          <strong>Role Specific Skills: </strong>
                          {userData.role_specific_skills}
                        </li>
                        <li className="list-group-item">
                          <strong>Resilience: </strong>
                          {userData.resilience}
                        </li>
                        <li className="list-group-item">
                          <strong>Communication: </strong>
                          {userData.communication}
                        </li>
                        <li className="list-group-item">
                          <strong>Collaboration: </strong>
                          {userData.collaboration}
                        </li>
                        <li className="list-group-item">
                          <strong>Creativity: </strong>
                          {userData.creativity}
                        </li>
                        <li className="list-group-item">
                          <strong>Work Ethic: </strong>
                          {userData.work_ethic}
                        </li>
                        <li className="list-group-item">
                          <strong>Leadership: </strong>
                          {userData.leadership}
                        </li>
                        <li className="list-group-item">
                          <strong>Entrepreneurship: </strong>
                          {userData.entrepreneurship}
                        </li>
                        <li className="list-group-item">
                          <strong>Agility: </strong>
                          {userData.agility}
                        </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="list-group-item">
                          <strong>Global Awareness: </strong>
                          {userData.global_awareness}
                        </li>
                        <li className="list-group-item">
                          <strong>Ethics: </strong>
                          {userData.ethics}
                        </li>
                        <li className="list-group-item">
                          <strong>Time Management: </strong>
                          {userData.time_management}
                        </li>
                        <li className="list-group-item">
                          <strong>Critical Thinking: </strong>
                          {userData.critical_thinking}
                        </li>
                        <li className="list-group-item">
                          <strong>Productivity: </strong>
                          {userData.productivity}
                        </li>
                        <li className="list-group-item">
                          <strong>Mentorship: </strong>
                          {userData.mentorship}
                        </li>
                        <li className="list-group-item">
                          <strong>Decision Making: </strong>
                          {userData.decision_making}
                        </li>
                        <li className="list-group-item">
                          <strong>Attention to Detail: </strong>
                          {userData.attention_to_detail}
                        </li>
                        <li className="list-group-item">
                          <strong>Emotional Intelligence: </strong>
                          {userData.emotional_intelligence}
                        </li>
                        <li className="list-group-item">
                          <strong>Self Motivation: </strong>
                          {userData.self_motivation}
                        </li>
                        <li className="list-group-item">
                          <strong>Accountability: </strong>
                          {userData.accountability}
                        </li>
                        <li className="list-group-item">
                          <strong>Interpersonal Skills: </strong>
                          {userData.interpersonal_skills}
                        </li>
                        <li className="list-group-item">
                          <strong>Feedback Reception: </strong>
                          {userData.feedback_reception}
                        </li>
                        <li className="list-group-item">
                          <strong>Stress Management: </strong>
                          {userData.stress_management}
                        </li>
                        <li className="list-group-item">
                          <strong>Self Discipline: </strong>
                          {userData.self_discipline}
                        </li>
                        <li className="list-group-item">
                          <strong>Public Speaking: </strong>
                          {userData.public_speaking}
                        </li>
                        <li className="list-group-item">
                          <strong>Innovation Thinking: </strong>
                          {userData.innovation_thinking}
                        </li>
                        <li className="list-group-item">
                          <strong>Project Management: </strong>
                          {userData.project_management}
                        </li>
                        <li className="list-group-item">
                          <strong>Technical Adaptability: </strong>
                          {userData.technical_adaptability}
                        </li>
                        <li className="list-group-item">
                          <strong>Workplace Maturity: </strong>
                          {userData.workplace_maturity}
                        </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="list-group-item">
                          <strong>Conflict Resolution: </strong>
                          {userData.conflict_resolution}
                        </li>
                        <li className="list-group-item">
                          <strong>Goal Orientation: </strong>
                          {userData.goal_orientation}
                        </li>
                        <li className="list-group-item">
                          <strong>Research Skills: </strong>
                          {userData.research_skills}
                        </li>
                        <li className="list-group-item">
                          <strong>Customer Focus: </strong>
                          {userData.customer_focus}
                        </li>
                        <li className="list-group-item">
                          <strong>Teamwork: </strong>
                          {userData.teamwork}
                        </li>
                        <li className="list-group-item">
                          <strong>Multitasking: </strong>
                          {userData.multitasking}
                        </li>
                        <li className="list-group-item">
                          <strong>Vision: </strong>
                          {userData.vision}
                        </li>
                        <li className="list-group-item">
                          <strong>Persistence: </strong>
                          {userData.persistence}
                        </li>
                        <li className="list-group-item">
                          <strong>Self Improvement: </strong>
                          {userData.self_improvement}
                        </li>
                        <li className="list-group-item">
                          <strong>Business Acumen: </strong>
                          {userData.business_acumen}
                        </li>
                        <li className="list-group-item">
                          <strong>Attendance: </strong>
                          {userData.attendance}
                        </li>
                        <li className="list-group-item">
                          <strong>Education: </strong>
                          {userData.user_education[0] &&
                          userData.user_education.length > 0
                            ? userData.user_education[0].field_of_study
                            : "null"}
                        </li>
                        <li className="list-group-item">
                          <strong>Experience: </strong>
                          {userData.user_experience &&
                          userData.user_experience.length > 0
                            ? userData.user_experience[0].field_of_study
                            : "null"}
                        </li>
                        <li className="list-group-item">
                          <strong>Certifications: </strong>
                          {userData.user_certifications &&
                          userData.user_certifications.length > 0
                            ? userData.user_certifications[0].field_of_study
                            : "null"}
                        </li>
                        <li className="list-group-item">
                          <strong>Honours: </strong>
                          {userData.user_honours &&
                          userData.user_honours.length > 0
                            ? userData.user_honours[0].field_of_study
                            : "null"}
                        </li>
                        <li className="list-group-item">
                          <strong>Technical skills: </strong>
                          {userData.user_technical_skills[0] &&
                          userData.user_technical_skills.length > 0
                            ? userData.user_technical_skills[0].title
                            : "null"}
                        </li>
                        <li className="list-group-item">
                          <strong>Interpersonal skills: </strong>
                          {userData.user_interpersonal_skills &&
                          userData.user_interpersonal_skills.length > 0
                            ? userData.user_interpersonal_skills[0]
                                .field_of_study
                            : "null"}
                        </li>
                        <li className="list-group-item">
                          <strong>Hobbies: </strong>
                          {userData.user_hobbies &&
                          userData.user_hobbies.length > 0
                            ? userData.user_hobbies[0].title
                            : "null"}
                        </li>
                      </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default PlacementItem;
