import React from 'react';
import CustomerSupport from './CustomerSupport';
import DoubtSupport from './DoubtSupport';
import CourseRegistrationStatus from './CourseRegistrationStatus'
import { Link } from 'react-router-dom';
import CourseRegistrations from './CourseRegistrations';
import PlacementSupport from './PlacementSupport';
import AmountStatusChart from './AmountStatusChart';
import PaymentStatusChart from './PaymentStatusChart';
import PaymentPayChart from './PaymentPayChart';
import UpcommingClass from './UpcommingClass';
import AssignmentSubmissions from './AssignmentSubmissions';
import ProjectSubmission from './ProjectSubmission';
import StudentSupportChart from './StudentSupportChart';
import TotalCourseRegistrations from './TotalCourseRegistrations';
import UsersChart from './UsersChart';
import Leads from './Leads';



const AllCharts = () => {
  return (
    <div className='d-flex flex-wrap position-y-fixed'>
      

     
      
        <div className='col-lg-3  '>
        <div className='m-1 '>
        <Link to="/customersupport"><CustomerSupport /></Link>
        </div>
        </div>

        <div className='col-lg-3 '>
          <div className=' m-1'>
          <Link to="/doubtsupport"><DoubtSupport /></Link>
          </div>
        
        </div>
        


        <div className='col-lg-6 mb-4'>
        <div className='m-1 shadow' >
      <Link to="/courseregistration"><CourseRegistrations/></Link>
       </div>
      </div>

      <div className='col-lg-3 mb-4'>
          <div className=' m-2'>
          <Link to="/placementsupport"><PlacementSupport/></Link>
          </div>
        </div>

        <div className='col-lg-3 mb-4 '>
          <div className=' m-2 '>
          <Link to="/studentsupport"><StudentSupportChart/></Link>
          </div>
        </div>



      <div className="col-lg-6 p-1">
    
        <Link to="/upcomingclass"><UpcommingClass/></Link>
      
    
      </div>

      <div className='col-lg-6 mb-2'>
        <div className='m-1 rounded shadow' style={{border:'1px solid gray'}}>
       <Link to="/CourseRegistrationStatus"><CourseRegistrationStatus/> </Link>
       </div>
       </div>


       <div className='col-lg-6 mb-2 rounded ' style={{
        border:'1px solid gray'
       }}>
        
        <Link to="/assignmentsubmission"><AssignmentSubmissions/></Link>
        <Link to="/projectsubmission"><ProjectSubmission/></Link>
        
       </div> 



    <div className="container-fluid">
    <div className="row">
    <div className="col-lg-4 col-md-6 mb-4 " >
      <div className=' rounded'  style={{border:'1px solid gray'}}>
      <Link to="/amountstatuschart"><AmountStatusChart /></Link>
      </div>
      
    </div>

    <div className="col-lg-4 col-md-6 mb-4 " >
      <div className='rounded'  style={{border:'1px solid gray'}}>
      <Link to="/paymentstatuschart"><PaymentStatusChart /></Link>
      </div>
      
    </div>

    <div className="col-lg-4 col-md-6 mb-4 rounded" style={{border:'1px solid gray'}}>
      <Link to="/paymentpaychart"><PaymentPayChart /></Link>
    </div>
   </div >
   </div>

      
  <div className='col-lg-6' >
    <div className='m-1 rounded ps-1' style={{border:'1px solid gray'}}>
    <Link to="/totalcourseregistrations"><TotalCourseRegistrations/></Link>
    </div>
    
  </div>

  <div className='col-lg-6 '>
    <div className='m-1 rounded' style={{border:'1px solid gray'}}>
    <Link to="/userschart"><UsersChart/></Link>
    </div>
  </div>

  <div className='col-lg-4 mt-4 rounded' style={{border:'1px solid gray'}}>
      <Link to="/leadschart"><Leads/></Link>
  </div>
        
      
      
        
      
      
    </div>
  );
};
const styles= {
  bord:{
    border:'1px solid gray'
  }
}

export default AllCharts;
