import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const DoubtSupport = () => {
  const [series] = useState([44, 55, 41]); // Example data for Initiate, UnResolved, Closed
  const options = {
    chart: {
      type: 'donut',
      width: 400,
    },
    labels: ['Initiated', 'UnResolved', 'Closed'],
    colors: ['#6E9BCF', '#F2A65A', '#FF6B6B'],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${Math.round(val)}%`, // Display percentages
      style: {
        colors: ['#333'], // Label color
        fontSize: '16px',
      },
    },
    title: {
      text: 'Doubt Support',
      align: 'center',
      margin: 20,
      
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      position: 'bottom',
      labels: {
        colors: ['#333'],
        useSeriesColors: true,
      },
    },
  };

  return (
    <div style={styles.container}>
      
      <div style={styles.card}>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width={450}
        />
      </div>
    </div>
  );
};

// Enhanced styles for layout and appearance
const styles = {
  
  card: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '450px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border:'1px solid gray',
  },

 

  title: {
    fontSize: '24px',
    fontWeight: '600',
    color: 'black',
    marginBottom: '15px',
  },
};

export default DoubtSupport;
