import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ref, push, onValue, getDatabase } from "firebase/database";
import AboutPage from "./pages/AboutPage";
import HomeSection from "./pages/HomePage";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import Navbar from "./components/Navbar";
import NavbarTheme from "./themes/NavbarTheme";
import EmptyTheme from "./themes/EmptyTheme";
import ProjectsListPage from "./pages/projects/ProjectsListPage";
import AnalyticsPage from "./pages/analytics/AnalyticsPage";
import ProjectsDashboard from "./pages/projects/ProjectsDashboard";
import WalletPage from "./pages/wallet/WalletPage";
import JobDashboard from "./pages/jobs/JobDashboard";
import ChatPage from "./pages/chat/ChatPage";
import ProductsPage from "./pages/products/ProductsPage";
import PaymentsList from "./pages/payments/PaymentsList";
import UsersList from "./pages/users/UsersList";
import CourseRegistrations from "./pages/course-registrations/CourseRegistrations";
import ProjectOverview from "./pages/projects/ProjectOverview";
import InvoicesList from "./pages/invoices/InvoicesList";
import CreateInvoice from "./pages/invoices/CreateInvoice";
import CandidatesList from "./pages/candidates/CandidatesList";
import Signin from "./pages/auth/Signin";
import StudentProfile from "./pages/students/StudentProfile";
import SuperAdminDashboard from "./pages/superadmin/SuperAdminDashboard";
import LeadsPage from "./pages/leads/LeadsPage";
import CompleteAnalytics from "./pages/analytics/CompleteAnalytics";
import StudentsList from "./pages/students/StudentsList";
import StudentSupportPage from "./pages/support/StudentsSupportPage";
import StudentsPlacementSupport from "./pages/support/StudentsPlacementSupport";
import CustomerSupportPage from "./pages/customer/CustomerSupportPage";
import ClassesManagement from "./pages/classes/ClassesManagement";
import firebaseConfig from "./firebase-Config";
import EmployeesList from "./pages/team/EmployeesList";
import CoursesList from "./pages/courses/CoursesList";
import BatchesList from "./pages/courses/BatchesList";
import TeamWorkSubmissions from "./pages/team/TeamWorkSubmissions";
import Withdrawls from "./pages/wallet/Withdrawls";
import CouponCodes from "./pages/coupons/CouponCodes";
import Recordings from "./pages/recordings/Recordings";
import Shorts from "./pages/videos/Shorts";
import Tutorials from "./pages/videos/Tutorials";
import JobHunt from "./pages/jobs/JobHunt";
import CampusAmbassadors from "./pages/campus-ambassadors/CampusAmbassadors";
import WorkshopRegistrations from "./pages/workshops/WorkshopRegistrations";
import ReviewsList from "./pages/reviews/ReviewsList";
import TechnicalQuizzes from "./pages/quizzes/TechnicalQuizzes";
import AssignmentSubmissions from "./pages/assignments/AssignmentSubmissions";
import ProjectSubmissions from "./pages/projects/ProjectSubmissions";
import BatchManagement from "./pages/courses/BatchManagement";
import TeamMemberProfile from "./pages/team/TeamMemberProfile";
import InternshipApplications from "./pages/internships/InternshipApplications";
import DoubtSupportDetails from "./pages/support/DoubtSupportDetails";
import CourseManagement from "./pages/courses/CourseManagement";
import Videos from "./pages/videos/Tutorials";
import HackingWorkshopRegistrations from "./pages/workshops/HackingWorkshopRegistrations";
import TeamMeetings from "./pages/meetings/TeamMeetings";
import Localbase from "localbase";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import ENDPOINT from "./context/EndPoint";
import axios from "axios";
import SecurityHeaders from "./context/SecurityHeaders";
import AddNewCourse from "./pages/courses/AddNewCourse";
import AddNewBatch from "./pages/courses/AddNewBatch";
import CourseTopicsManagement from "./pages/courses/CourseTopicsManagement";
import MockTestsManagement from "./pages/mock-tests/MockTestsManagement";
import CertificateManagement from "./pages/certifications/CertificateManagement";
import MockTestQuestions from "./pages/mock-tests/MockTestQuestions";
import ActiveBatchesList from "./pages/courses/ActiveBatchesList";
import ClassRecordings from "./pages/recordings/ClassRecordings";
import TeamList from "./pages/team/TeamList";
import { TeamProvider } from "./actions/team/TeamContext";
import { StudentDoubtSupportProvider } from "./context/support/StudentDoubtSupportContext";
import AllEmployeesLeaveRequests from "./pages/team/AllEmployeesLeaveRequests";
import TeamWorkSubmissionsPage from "./pages/team/TeamWorkSubmissionsPage";
import { CompleteDataProvider } from "./context/CompleteData";
import AllPosts from "./pages/posts/AllPosts";
import AllComments from "./pages/posts/AllComments";
import MilestonesPage from "./pages/milestones/MilestonesPage";
import AddInternship from "./pages/internships/AddInternship";
import DailyReports from "./pages/reports/DailyReports";
import AddDailyReport from "./pages/reports/AddDailyReport";
import WebsiteLogs from "./pages/website/WebsiteLogs";
import Expenses from "./pages/expenses/Expenses";
import PermissionsManagement from "./pages/security/PermissionsManagement";
import EditCourseOverview from "./pages/courses/EditCourseOverview";
import HackingInternshipRegistrations from "./pages/internships/HackingInternshipRegistrations";
import InternalVideoManagement from "./pages/videos/InternalVideoManagement";
import UploadVideo from "./pages/videos/UploadVideo";
import CampusAmbassadorsPage from "./pages/campus-ambassadors/CampusAmbassadorsPage";
import InternshipsList from "./pages/internships/InternshipsList";
import UpdateVideoDetails from "./pages/videos/UpdateVideoDetails";
import UploadShort from "./pages/videos/UploadShort";
import CreateCertificate from "./pages/certifications/CreateCertificate";
import WhatsAppSupport from "./pages/whatsapp/WhatsAppSupport";
import ClassManagerDashboard from "./pages/dashboard/ClassMaagerDashboard";
import CommunityManagerDashboard from "./pages/dashboard/CommunityManagerDashboard";
import HRManagerDashboard from "./pages/dashboard/HRManagerDashboard";
import CollegeLeadRegistrations from "./pages/college/CollegeLeadRegistrations";
import UpdateShort from "./pages/videos/UpdateShort";
import QuizAttemptHistory from "./pages/quizzes/QuizAttemptHistory";
import AmbassadorProfile from "./pages/campus-ambassadors/AmbassadorProfile";
import { ActionsProvider, useActionsContext } from "./context/ActionsContext";
import FreeResources from "./pages/resources/FreeResources";
import Test from "./Test";
import WhatsAppNotifications from "./pages/whatsapp/WhatsAppNotifications";
import PaymentScreenShots from "./pages/payments/PaymentScreenShots";
import MarketingManagerDashboard from "./pages/dashboard/MarketingManagerDashboard";
import CustomerSupportAdmin from "./pages/dashboard/CustomerSupportAdmin";
import FinanceManagerDashboard from "./pages/dashboard/FinanceManagerDashboard";
import PlacementOfficerDashboard from "./pages/dashboard/PlacementOfficerDashboard";
import TeamGroups from "./pages/community/TeamGroups";
import Test2 from "./Test2";
import DailyClassFeedbacks from "./pages/feedbacks/DailyClassFeedbacks";
import DetectDevice from "./context/DetectDevice";
import NotificationsPage from "./pages/notifications/NotificationsPage";

import ImageUploader from "./pages/photos/ImageUploader";
import InternalImageManager from "./pages/photos/InternalImageManager";
import ModeWiseCourseRegistration from "./components/charts/CourseRegistrationStatus";
import StudentRegistrations from "./components/charts/CourseRegistrations";
import CustomerSupport from "./components/charts/CustomerSupport";
import DoubtSupport from "./components/charts/DoubtSupport";
import PlacementSupport from "./components/charts/PlacementSupport";
import StudentSupport from "./components/charts/StudentSupportChart";
import UpcommingClass from "./components/charts/UpcommingClass";

import { redirect } from "react-router-dom";
import RecentRecordings from "./pages/dailyclasses/RecentRecordings";
import AddTeamMember from "./pages/support-team/AddTeamMember";
import AllTeamMembers from "./pages/support-team/AllTeamMembers";
import AddStudent from "./pages/placements/AddStudent";
import PlacementStudent from "./pages/placements/PlacementStudent";
import PlacementStudents from "./pages/placements/PlacementStudents";
import UploadImage from "./pages/images/UploadImage";
import AdminGetImages from "./pages/images/AdminGetImages";
import StudentsRankings from "./pages/internships/StudentsRankings";
import PlacementItem from "./pages/internships/PlacementItem";
import { PermDataSettingTwoTone } from "@material-ui/icons";

import AllCharts from "./components/charts/AllCharts";
import AssignmentSubmission from "./components/charts/AssignmentSubmissions";
import TotalCourseRegistrations from "./components/charts/TotalCourseRegistrations";
import ProjectSubmission from "./components/charts/ProjectSubmission";
import UsersChart from "./components/charts/UsersChart";
import UpcomimgClass from "./components/charts/UpcommingClass";
import PaymentPayChart from "./components/charts/PaymentPayChart";
import PaymentStatusChart from "./components/charts/PaymentStatusChart";
import AmountStatusChart from "./components/charts/AmountStatusChart";
import Leads from "./components/charts/Leads";
import StudentSupportChart from "./components/charts/StudentSupportChart";

function App() {
  const { user } = useContext(AuthContext);

  const db = new Localbase("db");

  const [permission, setpermission] = useState(
    JSON.parse(sessionStorage.getItem("permissions")) || null,
  );

  const [account, setaccount] = useState(null);

  useEffect(() => {
    db.collection("paths")
      .doc("user")
      .get()
      .then((user) => {
        setaccount(user.user);
      })
      .catch((error) => {});

    if (user !== null || user !== "null") {
      getaccount();
    }
  }, [user, window.location.pathname]);

  useEffect(() => {
    if (permission === null) {
    }
  }, [permission]);

  const getaccount = async () => {
    try {
      fetch(`${ENDPOINT}get-account.php?session=${user.SessionId}`)
        .then((r) => r.json())
        .then((result) => {
          if (result) {
            if (result.status === "success" && result.data != null) {
              setaccount(result.data);
              db.collection("paths").add(
                {
                  user: result.data,
                },
                "user",
              );
              GetPermissions();
            } else {
              try {
                db.collection("paths").doc("user").delete();

                db.collection("paths").doc("notifications").delete();

                db.collection("paths").doc("profile").delete();
              } catch (error) {}
              //localStorage.setItem("user",null);
              // window.location.replace("/")
            }
          } else {
            localStorage.setItem("user", null);
            window.location.replace("/");
          }
        });
    } catch (err) {}
  };

  const GetPermissions = async () => {
    const data = new FormData();
    if (user.SessionId) {
      data.append("session", user.SessionId);
      const res = await axios.post(
        ENDPOINT + "admin-permissions.php",
        data,
        SecurityHeaders,
      );

      if (res) {
        if (res.data) {
          if (
            (res.data.status === "success") &
            (res.data.permissions !== null)
          ) {
            setpermission(res.data.permissions);
            sessionStorage.setItem(
              "permissions",
              JSON.stringify(res.data.permissions),
            );
          } else {
          }
        }
      }
    }
  };

  const [showsidebar, setshowsidebar] = useState(false);
  const [shownavbar, setshownavbar] = useState(true);
  useEffect(() => {}, [window.location.pathname]);
  const pathname = window.location.pathname;
  const updateActivity = async () => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    if (user) {
      formData.append("session", user.SessionId);
    }

    formData.append("page", pathname);

    const res = await axios.post(ENDPOINT + "save-activity.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      //  // console.log("activity "+JSON.stringify(res))
    }
  };

  useEffect(() => {
    updateActivity();
  }, [window.location]);

  useEffect(() => {
    const device = DetectDevice();
    if (device === "mobile") {
      setshowsidebar(false);
    }
    if (permission) {
      let path = window.location.pathname;

      if (permission) {
        setshownavbar(true);
        setshowsidebar(true);

        if (path.includes("login")) {
          setshownavbar(false);
          setshowsidebar(false);
        } else {
          if (account && permission) {
            setshowsidebar(true);
            setshownavbar(true);
          } else {
            setshowsidebar(false);

            setshownavbar(false);
          }
        }
      } else {
        setshowsidebar(false);
        setshownavbar(false);
        if (path.includes("login")) {
          setshownavbar(false);
          setshowsidebar(false);
        } else {
        }
      }
    }
    if (!user) {
      setshowsidebar(false);
    }
  }, [user, window.location.pathname, permission]);

  return (
    <>
      <ActionsProvider>
        <BrowserRouter>
          {account && permission ? (
            <>
              <NavbarTheme
                account={account}
                showsidebar={showsidebar}
                shownavbar={shownavbar}
              >
                <Routes>
                  {account && permission ? (
                    <>
                      {parseInt(permission.test) ? (
                        <>
                          <Route path="/test" element={<Test2 />} />
                        </>
                      ) : null}

                      {parseInt(permission.class_feedbacks) ? (
                        <>
                          <Route
                            path="/class-feedbacks"
                            element={<DailyClassFeedbacks />}
                          />
                          <Route
                            path="/class-feedbacks/:course_url/:class_id"
                            element={<DailyClassFeedbacks />}
                          />
                        </>
                      ) : null}

                      {parseInt(permission.recent_recordings) ? (
                        <>
                          <Route
                            path="/recent-recordings"
                            element={<RecentRecordings />}
                          />
                        </>
                      ) : null}

                      {parseInt(permission.create_invoice) ? (
                        <>
                          <Route
                            path="create-invoice-2"
                            element={<CreateInvoice />}
                          />
                        </>
                      ) : null}

                      {parseInt(permission.team_groups) ? (
                        <>
                          <Route
                            path="/teams-groups"
                            element={<TeamGroups />}
                          />
                        </>
                      ) : null}

                      {parseInt(permission.payment_screenshots) ? (
                        <>
                          <Route
                            path="/payment-screen-shots"
                            element={<PaymentScreenShots />}
                          />
                        </>
                      ) : null}

                      {parseInt(permission.hr_dashboard) ? (
                        <>
                          <Route
                            path="/hr-dashboard"
                            element={<HRManagerDashboard />}
                          ></Route>
                        </>
                      ) : null}

                      {parseInt(permission.marketing_manager_dashboard) ? (
                        <>
                          <Route
                            path="/marketing-dashboard"
                            element={<MarketingManagerDashboard />}
                          ></Route>
                        </>
                      ) : null}

                      {parseInt(permission.class_manager_dashboard) ? (
                        <Route
                          path="/class-dashboard"
                          element={<ClassManagerDashboard />}
                        ></Route>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.customer_support_admin) ? (
                        <>
                          <Route
                            path="/customer-dashboard"
                            element={<CustomerSupportAdmin />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.placement_officer_dashboard) ? (
                        <>
                          <Route
                            path="/placement-dashboard"
                            element={<PlacementOfficerDashboard />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.finance_manager_dashboard) ? (
                        <>
                          <Route
                            path="/finance-dashboard"
                            element={<FinanceManagerDashboard />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.marketing) ? (
                        <>
                          <Route
                            path="/marketing"
                            element={<WhatsAppNotifications />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.leads) ? (
                        <>
                          <Route path="/leads" element={<LeadsPage />} />
                          <Route
                            path="/student-profile/:student_id"
                            element={<StudentProfile />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.internships_management) ? (
                        <>
                          <Route
                            path="/internship-applications"
                            element={<InternshipApplications />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.placement_cell_leaderboard) ? (
                        <>
                          <Route
                            path="/placement-cell-leaderboard"
                            element={<StudentsRankings />}
                          />
                          <Route
                            path="/placement-cell-leaderboard/:id"
                            element={<PlacementItem />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.quiz_attempts) ? (
                        <>
                          <Route
                            path="/quiz-attempts"
                            element={<QuizAttemptHistory />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.daily_reports) ? (
                        <>
                          <Route
                            path="/daily-reports"
                            element={<DailyReports />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.mile_stone) ? (
                        <>
                          <Route
                            path="/milestones"
                            element={<MilestonesPage />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {/* whatsapp  start */}
                      {parseInt(permission.whatapp_support) ? (
                        <>
                          <Route
                            path="/whatsapp/:phone_number"
                            element={<WhatsAppSupport />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.classes_management) ? (
                        <>
                          <Route
                            path="/classes-management"
                            element={<ClassManagerDashboard />}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {parseInt(permission.college_registrations) ? (
                        <>
                          <Route
                            path="/college-registrations"
                            element={<CollegeLeadRegistrations />}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* whatsapp  end */}

                      {parseInt(permission.super_admin_dashboard) ? (
                        <>
                          <Route
                            path="/"
                            element={
                              <>
                                {permission && user && account ? (
                                  <>
                                    <SuperAdminDashboard
                                      options={{ userinfo: true }}
                                      permission={permission}
                                    />
                                  </>
                                ) : null}
                              </>
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.classes_management) ? (
                        <>
                          <Route
                            path="/classes-management"
                            element={<ClassManagerDashboard />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.add_daily_report) ? (
                        <>
                          <Route
                            path="/add-daily-report"
                            element={<AddDailyReport />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.job_applications) ? (
                        <>
                          <Route
                            path="/job-applications"
                            element={<CandidatesList />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.create_invoice_1) ? (
                        <>
                          <Route
                            path="/create-invoice-1"
                            element={<CreateInvoice />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.invoices_list) ? (
                        <>
                          <Route
                            path="/invoices-list"
                            element={<InvoicesList />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.project_overview) ? (
                        <>
                          <Route
                            path="/project-overview"
                            element={<ProjectOverview />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.course_registrations) ? (
                        <>
                          <Route
                            path="/course-registrations"
                            element={<CourseRegistrations />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.users) ? (
                        <>
                          <Route
                            path="/users"
                            element={<UsersList permission={permission} />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.payments) ? (
                        <>
                          <Route path="/payments" element={<PaymentsList />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.products) ? (
                        <>
                          <Route path="/products" element={<ProductsPage />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.chat) ? (
                        <>
                          <Route path="/chat" element={<ChatPage />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.job_dashboard) ? (
                        <>
                          <Route
                            path="/job-dashboard"
                            element={<JobDashboard />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.analytics) ? (
                        <>
                          <Route
                            path="/analytics"
                            element={
                              <>
                                <AnalyticsPage />
                              </>
                            }
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.projects_dashboard) ? (
                        <>
                          <Route
                            path="/projects-dashboard"
                            element={<ProjectsDashboard />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.edit_course) ? (
                        <>
                          <Route
                            path="/edit-course/:course_id"
                            element={<EditCourseOverview />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.wallet) ? (
                        <>
                          <Route
                            path="/wallet"
                            element={<WalletPage />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.leads) ? (
                        <>
                          {" "}
                          <Route path="/leads" element={<LeadsPage />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.notifications) ? (
                        <>
                          {" "}
                          <Route
                            path="/notifications"
                            element={<NotificationsPage />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.about) ? (
                        <>
                          {" "}
                          <Route
                            path="/about"
                            element={
                              <NavbarTheme>
                                <AboutPage />
                              </NavbarTheme>
                            }
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.permissions) ? (
                        <>
                          <Route
                            path="/permissions/:user_id"
                            element={<PermissionsManagement />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.add_internships) ? (
                        <>
                          <Route
                            path="/add-internship"
                            element={<AddInternship />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.batch_management) ? (
                        <>
                          <Route
                            path="/batch-management/:course_url/:batch_id"
                            element={
                              <>
                                <BatchManagement />
                              </>
                            }
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.complete_analytics) ? (
                        <>
                          {" "}
                          <Route
                            path="/complete-analytics"
                            element={<CompleteAnalytics />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.projects) ? (
                        <>
                          <Route
                            path="/projects"
                            element={<ProjectsListPage />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.videos) ? (
                        <>
                          <Route
                            path="/videos"
                            element={<InternalVideoManagement />}
                          />
                          <Route
                            path="/videos/edit/:video_id"
                            element={<UpdateVideoDetails />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.update_shorts) ? (
                        <>
                          <Route
                            path="/short/edit/:video_id"
                            element={<UpdateShort />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.students) ? (
                        <>
                          <Route path="/students" element={<StudentsList />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.upload_image) ? (
                        <>
                          <Route
                            path="/upload-image"
                            element={<UploadImage />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.get_images) ? (
                        <>
                          <Route
                            path="/get-images"
                            element={<AdminGetImages />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.upload_shorts_video) ? (
                        <>
                          <Route
                            path="/shorts/upload"
                            element={<UploadShort />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.courses_management) ? (
                        <>
                          <Route
                            path="/course-management/:course_url/:course_id"
                            element={<CourseManagement />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.students_management) ? (
                        <>
                          {" "}
                          <Route
                            path="/students-support"
                            element={
                              <StudentDoubtSupportProvider>
                                <StudentSupportPage />
                              </StudentDoubtSupportProvider>
                            }
                          />
                          <Route
                            path="/students-support/:id"
                            element={<DoubtSupportDetails />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.placement_students) ? (
                        <>
                          <Route
                            path="/placement_students"
                            element={<PlacementStudents />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.add_students) ? (
                        <>
                          <Route path="/add_student" element={<AddStudent />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.placement_support) ? (
                        <>
                          {" "}
                          <Route
                            path="/placement-support"
                            element={<StudentsPlacementSupport />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.team_members_all) ? (
                        <>
                          {" "}
                          <Route
                            path="/team-members-all"
                            element={<AllTeamMembers />}
                          />
                          <Route
                            path="/team-members-all/add-member"
                            element={<AddTeamMember />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.mile_stone) ? (
                        <>
                          <Route
                            path="/milestones"
                            element={<MilestonesPage />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.active_batches) ? (
                        <>
                          <Route
                            path="/active-batches"
                            element={<ActiveBatchesList />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.customer_support) ? (
                        <>
                          <Route
                            path="/customer-support"
                            element={
                              <CustomerSupportPage options={{ limit: 100 }} />
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.certificate_management) ? (
                        <>
                          <Route
                            path="/certificates"
                            element={<CertificateManagement />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.course_certification) ? (
                        <>
                          <Route
                            path="/course-certification/:course_url"
                            element={<MockTestsManagement />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.mock_tests_management) ? (
                        <>
                          {" "}
                          <Route
                            path="/mock-tests/:course_url"
                            element={<MockTestsManagement />}
                          />
                          <Route
                            path="/:test_type/:course_url/test-code/:test_code"
                            element={<MockTestQuestions />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.add_courses) ? (
                        <>
                          <Route
                            path="/add-course"
                            element={<AddNewCourse />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.add_batch) ? (
                        <>
                          <Route
                            path="/add-batch/:course_url/:course_id"
                            element={<AddNewBatch />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.courses_management) ? (
                        <>
                          <Route
                            path="/course-management/:course_url/chapters/:chapter_id"
                            element={<CourseTopicsManagement />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.technical_quizzes) ? (
                        <>
                          {" "}
                          <Route
                            path="/technical-quizzes"
                            element={<TechnicalQuizzes />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.reviews) ? (
                        <>
                          <Route path="/reviews" element={<ReviewsList />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.classes_management) ? (
                        <>
                          {" "}
                          <Route
                            path="/classes"
                            element={<ClassesManagement />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.team) ? (
                        <>
                          {" "}
                          <Route
                            path="/team"
                            element={
                              <TeamProvider>
                                <TeamList />
                              </TeamProvider>
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.courses) ? (
                        <>
                          <Route path="/courses" element={<CoursesList />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.posts) ? (
                        <>
                          <Route path="/posts" element={<AllPosts />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.comments) ? (
                        <>
                          <Route path="/comments" element={<AllComments />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.batches) ? (
                        <>
                          <Route path="/batches" element={<BatchesList />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.withdrawls) ? (
                        <>
                          <Route path="/withdrawls" element={<Withdrawls />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.job_hunt) ? (
                        <>
                          <Route path="/job-hunt" element={<JobHunt />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.tutorials) ? (
                        <>
                          <Route path="/tutorials" element={<Tutorials />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.internship_list) ? (
                        <>
                          {" "}
                          <Route
                            path="/internships"
                            element={<InternshipsList />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.shorts) ? (
                        <>
                          <Route path="/shorts" element={<Shorts />} />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.campus_ambassadors) ? (
                        <>
                          {" "}
                          <Route
                            path="/campus-ambassadors"
                            element={<CampusAmbassadorsPage />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.recordings) ? (
                        <>
                          <Route
                            path="/recordings"
                            element={<ClassRecordings />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.team_member) ? (
                        <>
                          <Route
                            path="/team-member/internship/:internship_id/:user_id"
                            element={<TeamMemberProfile />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.ambassador_profile) ? (
                        <>
                          <Route
                            path="/campus-ambassadors-profile/:user_id"
                            element={<AmbassadorProfile />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.workshop_registrations) ? (
                        <>
                          <Route
                            path="/workshop-registrations"
                            element={<WorkshopRegistrations />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.hacking_workshop_registrations) ? (
                        <>
                          {" "}
                          <Route
                            path="/hacking-workshop-registrations"
                            element={<HackingWorkshopRegistrations />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.technical_quizzes) ? (
                        <>
                          {" "}
                          <Route
                            path="/technical-quizzes"
                            element={<WorkshopRegistrations />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.hacking_internship_registrations) ? (
                        <>
                          <Route
                            path="/hacking-internship-registrations"
                            element={<HackingInternshipRegistrations />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.website_log) ? (
                        <>
                          <Route
                            path="/website-logs"
                            element={<WebsiteLogs />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.team_meetings) ? (
                        <>
                          <Route
                            path="/team-meetings"
                            element={<TeamMeetings />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.team_leaves) ? (
                        <>
                          <Route
                            path="/team-leaves"
                            element={<AllEmployeesLeaveRequests />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.upload_video) ? (
                        <>
                          <Route
                            path="/videos/upload"
                            element={<UploadVideo />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.coupon_codes) ? (
                        <>
                          <Route
                            path="/coupon-codes"
                            element={<CouponCodes />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.team_tasks) ? (
                        <>
                          <Route
                            path="/team-task-submissions"
                            element={<TeamWorkSubmissionsPage />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.assignment_submissions) ? (
                        <>
                          <Route
                            path="/assignment-submissions"
                            element={<AssignmentSubmissions />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.project_submissions) ? (
                        <>
                          {" "}
                          <Route
                            path="/project-submissions"
                            element={<ProjectSubmissions />}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.contact) ? (
                        <>
                          <Route
                            path="/contact"
                            element={
                              <EmptyTheme>
                                <ContactPage />
                              </EmptyTheme>
                            }
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.expenses_management) ? (
                        <>
                          <Route
                            path="/expenses"
                            element={<Expenses />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.test) ? (
                        <>
                          {" "}
                          <Route
                            path="/test/:username/:user_id"
                            element={
                              <>
                                <ContactPage />
                              </>
                            }
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.create_certificates) ? (
                        <>
                          <Route
                            path="/create-certificate"
                            element={<CreateCertificate />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {/*dropdown added by manoj*/}

                      {parseInt(permission.free_resources) ? (
                        <>
                          <Route
                            path="/free-resources"
                            element={<FreeResources />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.upload_team_image) ? (
                        <>
                          <Route
                            path="/uploadimage"
                            element={<ImageUploader />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.image_internal_manager) ? (
                        <>
                          <Route
                            path="/image-internal-manager"
                            element={<InternalImageManager />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.course_registration_status_chart) ? (
                        <>
                          <Route
                            path="/Course-Registration-Status-chart"
                            element={<ModeWiseCourseRegistration />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.course_registration_chart) ? (
                        <>
                          <Route
                            path="/course-registration-chart"
                            element={<StudentRegistrations />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.customer_support_chart) ? (
                        <>
                          <Route
                            path="/customer-support-chart"
                            element={<CustomerSupport />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.doubt_support_chart) ? (
                        <>
                          <Route
                            path="/doubt-support-chart"
                            element={<DoubtSupport />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.placement_support_chart) ? (
                        <>
                          <Route
                            path="/placement-support-chart"
                            element={<PlacementSupport />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.student_support_chart) ? (
                        <>
                          <Route
                            path="/studentsupport"
                            element={<StudentSupport />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.all_charts) ? (
                        <>
                          <Route
                            path="/allcharts"
                            element={<AllCharts />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.assignment_submission_chart) ? (
                        <>
                          <Route
                            path="/assignmentsubmission-chart"
                            element={<AssignmentSubmission />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.totalcourseregistrations_chart) ? (
                        <>
                          <Route
                            path="/totalcourseregistrations-chart"
                            element={<TotalCourseRegistrations />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.projectsubmission_chart) ? (
                        <>
                          <Route
                            path="/projectsubmission-chart"
                            element={<ProjectSubmission />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.users_chart) ? (
                        <>
                          <Route
                            path="/users-chart"
                            element={<UsersChart />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.upcomingclass_chart) ? (
                        <>
                          <Route
                            path="/upcomingclass-chart"
                            element={<UpcomimgClass />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.paymentstatus_chart) ? (
                        <>
                          <Route
                            path="/paymentstatus-chart"
                            element={<PaymentStatusChart />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.paymentpay_chart) ? (
                        <>
                          <Route
                            path="/paymentpay-chart"
                            element={<PaymentPayChart />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.amountstatus_chart) ? (
                        <>
                          <Route
                            path="/amountstatus-chart"
                            element={<AmountStatusChart />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.leads_chart) ? (
                        <>
                          <Route
                            path="/leads-chart"
                            element={<Leads />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {parseInt(permission.studentsupport_chart) ? (
                        <>
                          <Route
                            path="/studentsupport-chart"
                            element={<StudentSupportChart />}
                          ></Route>
                        </>
                      ) : (
                        <></>
                      )}

                      {/*dropdown added by manoj*/}
                    </>
                  ) : (
                    <></>
                  )}
                </Routes>
              </NavbarTheme>
            </>
          ) : (
            <EmptyTheme>
              <Routes>
                <Route
                  path="/"
                  element={account && permission ? null : <Signin />}
                ></Route>
              </Routes>
            </EmptyTheme>
          )}
        </BrowserRouter>
      </ActionsProvider>
    </>
  );
}

export default App;
