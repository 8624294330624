import React from 'react';
import Chart from 'react-apexcharts';

function AmountStatusChart() {
  const chartOptions = {
    series: [55, 45], 
    options: {
      chart: {
        type: 'donut',
        width: 400,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 2,
          blur: 10, 
          color: '#000',
          opacity: 0.25
        }
      },
      labels: ['Success', 'Pending'], 
      colors: ['#00E396', '#FF4560'], 
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            size: '70%',
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                color: '#333',
                fontSize: '16px',
                fontWeight: 'bold'
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => `${val.toFixed(1)}%`,
        style: {
          colors: ['#333'],
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      legend: {
        position: 'bottom',
        fontSize: '16px',
        fontWeight: 'bold',
        labels: {
          colors: ['#333']
        },
        markers: {
          width: 12,
          height: 12,
          radius: 2
        }
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        y: {
          formatter: (val) => `${val}%`
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Amount Status</h2>
        <Chart
          options={chartOptions.options}
          series={chartOptions.series}
          type="donut"
          width={440}
        />
      </div>
    </div>
  );
}

// Enhanced shared styles
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    // padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    
  },
  card: {
    backgroundColor: '#fff',
    padding: '30px',
    // borderRadius: '12px',
    // boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    maxWidth: '450px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '15px',
  },
};

export default AmountStatusChart;
