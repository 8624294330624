import axios from 'axios';
import React, { useState } from 'react';

const ImageUploader = () => {
    const [image, setImage] = useState(null);
    const [page, setPage] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    // Handle file and input changes
    const handleImageChange = (e) => setImage(e.target.files[0]);
    const handlePageChange = (e) => setPage(e.target.value);
    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);

    // Upload function
    const upload = () => {
        const formData = new FormData();
        formData.append("image_url", image);
        formData.append("page", page);
        formData.append("title", title);
        formData.append("description", description);

        axios.post("https://skillsuprise.com/insert-photos.php", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log("Upload successful:", res.data);
            alert("Upload successful!");
        })
        .catch((error) => {
            console.error("Upload failed:", error);
            alert("Upload failed. Please try again.");
        });
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Upload Image</h2>
            <input type="file" onChange={handleImageChange} style={styles.input} />
            <input 
                type="text" 
                placeholder="Page" 
                value={page} 
                onChange={handlePageChange} 
                style={styles.input}
            />
            <input 
                type="text" 
                placeholder="Title" 
                value={title} 
                onChange={handleTitleChange} 
                style={styles.input}
            />
            <textarea 
                placeholder="Description" 
                value={description} 
                onChange={handleDescriptionChange} 
                style={styles.textarea}
            />
            <button onClick={upload} style={styles.button}>Upload</button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        maxWidth: '400px',
        margin: '0 auto',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
    },
    header: {
        color: '#333',
        marginBottom: '20px',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ddd',
        fontSize: '16px'
    },
    textarea: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ddd',
        fontSize: '16px',
        resize: 'vertical'
    },
    button: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#28a745',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '10px'
    }
};

export default ImageUploader;