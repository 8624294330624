// TeamList.jsx
import React, { useContext, useEffect, useState } from 'react';
import { useTeamData } from '../../actions/team/TeamContext';
import TeamItem from './TeamItem';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint';
import Localbase from 'localbase';
import { useActionsContext } from '../../context/ActionsContext';

const TeamList = () => {

  const db = new Localbase("db")

  const { user } = useContext(AuthContext)
  const fetchTeamData = async (payload) => {
    try {
      const formData = new FormData();
      formData.append('session', user.SessionId);

      const res = await axios.post(ENDPOINT + "get-internal-team-list.php", formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      if (res) {
        if (res.data.status === "success") {
          var data = res.data.data
          db.collection("paths").add({ data }, "internal_team")
        }

      }

      if (res) {
        setInitialData(res.data.data)
        setResponse(res.data.data)
      } else {
        // Handle error
      }
    } catch (error) {
      // Handle error
    }
  };

  //offline func........
  const getOfflinedata = () =>{
    db.collection('paths').doc('internal_team').get().then((data)=>{
      if(data){
        setResponse(data.data)
      }else{
        fetchTeamData()
      }
    })
  }

  useEffect(() => {
    fetchTeamData()
    
    getOfflinedata()
  }, [])
  
  // Use teamData to render team list
  const [initialData, setInitialData] = useState('')
  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');
  const { searchquery } = useActionsContext()
  const handleSearch = ({ term }) => {
    if(!term)
    {
      setResponse(initialData)
      setSearchTerm("")
      return
    }
    if (term && initialData) {

      const filteredData = initialData.filter((item) => {
        return (
          item.first_name.toLowerCase().includes(term) ||
          item.last_name.toLowerCase().includes(term) ||
          item.joining_datetime.toLowerCase().includes(term) ||
          item.last_active_time.toLowerCase().includes(term)
        );
      });
      setResponse(filteredData);
      setSearchTerm(term);
    }


  };
  useEffect(() => {
    handleSearch({ term: searchquery })
  }, [searchquery])
  // const {voiceinput} = useActionsContext()
  // useEffect(()=>{

  // var input =  voiceinput.replace(" ",'')
  // if(input.includes("scroll")){

  //   if(input.includes("down")){
  //     window.scrollBy({
  //       top: -500, // Negative value to scroll upwards
  //       behavior: 'smooth' // Optional: Smooth scrolling
  //     });
  //   }
  // }
  // },[])

  return (
    <div>


      <div className="container-fluid">
      {/* Previous Search Filters */}
        {/* <div className="card-body border border-dashed border-end-0 border-start-0">
          <form className="d-none d-sm-block d-lg-block d-xl-block ">
            <div className="row g-3">
              <div className="col-xxl-5 col-sm-6">
                <div className="search-box">
                  <input
                    onChange={(e) => { handleSearch({ term: e.target.value.toLowerCase() }) }}
                    type="text"
                    className="form-control search"
                    placeholder="Search for username, email or phone number..."
                  />
                  <i className="ri-search-line search-icon" />
                </div>
              </div>
              <div className="col-xxl-2 col-sm-6">
                <div>
                  <input
                    type="date"
                    onChange={(e) => { handleSearch({ term: e.target.value.toLowerCase() }) }}

                    className="form-control"
                    data-provider="flatpickr"
                    data-date-format="M, Y"
                    data-range-date="true"
                    id="demo-datepicker"

                  />
                </div>
              </div>
              <div className="col-xxl-2 col-sm-4 d-none">
                <div>
                  <select
                    onChange={(e) => { handleSearch({ term: e.target.value.toLowerCase() }) }}
                    className="form-control"
                    data-choices=""
                    data-choices-search-false=""
                    name="choices-single-default"
                    id="idStatus"
                  >
                    <option value="">Status</option>
                    <option value="all" selected="">
                      All
                    </option>
                    <option value="Pending">Pending</option>
                    <option value="Inprogress">Inprogress</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Pickups">Pickups</option>
                    <option value="Returns">Returns</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>
              <div className="col-xxl-2 col-sm-4 d-none">
                <div>
                  <select
                    className="form-control"
                    data-choices=""
                    data-choices-search-false=""
                    name="choices-single-default"
                    id="idPayment"
                  >
                    <option value="">Select Payment</option>
                    <option value="all" selected="">
                      All
                    </option>
                    <option value="Mastercard">Mastercard</option>
                    <option value="Paypal">Paypal</option>
                    <option value="Visa">Visa</option>
                    <option value="COD">COD</option>
                  </select>
                </div>
              </div>
              <div className="col-xxl-1 col-sm-4">
                <div>
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onclick="SearchData();"
                  >
                    {" "}
                    <i className="ri-equalizer-fill me-1 align-bottom" />
                    Filters
                  </button>
                </div>
              </div>
            </div>
           
          </form>
        </div> */}
        <div className="row mt-3">
          {response ?
            <>
              {response.map((item) => (
                <>
 
                  <div className="col-xxl-3 col-sm-6 project-card">
                    <TeamItem item={item} />
                  </div>
                </>
              ))}
            </> :
            <>

            </>}
          {/* end col */}


          {/* end col */}
        </div>

        {/* end row */}
        <div className="row g-0 text-center text-sm-start align-items-center mb-4">
          <div className="col-sm-6">
            <div>
              <p className="mb-sm-0 text-muted">
                Showing <span className="fw-semibold">1</span> to{" "}
                <span className="fw-semibold">10</span> of{" "}
                <span className="fw-semibold text-decoration-underline">
                  12
                </span>{" "}
                entries
              </p>
            </div>
          </div>
          {/* end col */}
          <div className="col-sm-6">
            <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
              <li className="page-item disabled">
                <a href="#" className="page-link">
                  Previous
                </a>
              </li>
              <li className="page-item active">
                <a href="#" className="page-link">
                  1
                </a>
              </li>
              <li className="page-item ">
                <a href="#" className="page-link">
                  2
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  3
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  4
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  Next
                </a>
              </li>
            </ul>
          </div>
          {/* end col */}
        </div>
        {/* end row */}
      </div>
    </div>
  );
};

export default TeamList;
