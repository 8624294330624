import React from "react";

import Rating from "@mui/material/Rating";
import { Avatar, Chip } from "@mui/material";
import { Link } from "react-router-dom";
import DetectDevice from "../../context/DetectDevice";

const PlacementRecommendationItem = ({ item }) => {
  const device = DetectDevice();
  console.log(device);
  console.log(item)
  return (
    <>
      {device === "mobile" ? (
        <>
          <div className="card mb-0 p-2">
            <div className="card-body">
              <div className="d-lg-flex align-items-center">
                <div className=" mb-2 my-lg-0">
                  <div className="d-flex">
                    <Link to="/students/" className="d-flex">
                      <Avatar className="me-2 " src={item.profile_pic} />
                      <div>
                        <h5 className="fs-16 m-0">
                          {item.first_name + " " + item.last_name}
                        </h5>
                        <div>
                          <span className="badge bg-warning text-light">
                            Good Match
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="d-flex align-items-center">
                    <div>
                      <p className="text-muted mb-0 me-3">{item.title}</p>
                    </div>
                  </div>

                  <div className="d-flex"></div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <i className="ri-time-line text-primary me-1 align-bottomr" />
                    <span className="badge bg-danger-subtle text-danger">
                      Part Time
                    </span>
                  </div>
                  <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-lg-0">
                    <div className="badge text-bg-success">
                      <i className="mdi mdi-star me-1" />
                      {item.average_score}
                    </div>
                    <div className="text-muted">2 Job matchings</div>
                  </div>
                  <div>
                    <a
                      href="#!"
                      className="btn btn-ghost-danger btn-icon custom-toggle mt-0"
                      data-bs-toggle="button"
                      aria-pressed="false"
                    >
                      <span className="icon-on">
                        <i className="ri-bookmark-line align-bottom" />
                      </span>
                      <span className="icon-off">
                        <i className="ri-bookmark-3-fill align-bottom" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card mb-0 p-2">
            <div className="card-body">
              <div className="d-lg-flex align-items-center">
                <div className=" mb-2 my-lg-0">
                  <div className="d-flex align-items-center">
                    <Link to="/students/" className="d-flex">
                      <Avatar className="me-2 " src={item.profile_pic} />

                      <div>
                        <div>
                          <h5 className="fs-16 mb-2">
                            {item.first_name + " " + item.last_name}
                          </h5>
                          <i className="ri-map-pin-2-line text-muted me-1 align-bottom" />
                          Cullera, Spain
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="d-flex align-items-center">
                    <p className="text-muted mb-0 me-3">{item.title}</p>
                  </div>

                  <div className="d-flex"></div>
                </div>
                <div className="d-flex gap-4 mt-0 text-muted mx-auto">
                  <div>
                    <i className="ri-time-line text-primary me-1 align-bottom" />
                    <span className="badge bg-danger-subtle text-danger">
                      Part Time
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-3 my-lg-0">
                  <div className="badge text-bg-success">
                    <i className="mdi mdi-star me-1" />
                    4.2{item.average_score}
                  </div>
                  <div className="text-muted">2 Job matchings</div>
                </div>

                <Chip
                  size="small"
                  className="d-flex me-3 bg-warning align-items-center"
                  label={
                    <>
                      <div className="d-flex align-items-center">
                        <h6 className="m-0 text-light">Good Match</h6>
                      </div>
                    </>
                  }
                />
                <div>
                  <Link to={"/placement-cell-leaderboard/"+item.user_id}>
                     <button className="btn btn-soft-success"> View Details</button>
                  </Link>
                
                  <a
                    href="#!"
                    className="btn btn-ghost-danger btn-icon custom-toggle"
                    data-bs-toggle="button"
                    aria-pressed="false"
                  >
                    <span className="icon-on">
                      <i className="ri-bookmark-line align-bottom" />
                    </span>
                    <span className="icon-off">
                      <i className="ri-bookmark-3-fill align-bottom" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlacementRecommendationItem;
