import { MoreVert } from '@material-ui/icons'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import DeleteModal from '../../components/modals/DeleteModal'
import DefaultModal from '../../components/modals/DefaultModal'
import EditWorkshopRegistration from './EditWorkshopRegistration'
import { Link } from 'react-router-dom'
import dateFormat from 'dateformat'
import { AttachEmail, Delete, Email, Preview, ScheduleSend, WhatsApp } from '@mui/icons-material'
import { Save } from 'react-bootstrap-icons'
import SecurityHeaders from '../../context/SecurityHeaders'
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint'
const RegistrationItem = ({item,SelectUser}) => {
    const [show,setshow] = useState(false)

    const HandleClose = ()=>{

setshow(false)

    }
    const HandleDelete = ()=>{



    }

    const [edit,setedit] = useState(false)
    const CloseEdit = ()=>{

        setedit(false)
    }
    const [selected,setselected] = useState(false)

const [createcertificate,setcreatecertificate]=useState(false)

const date = "4th Mar 24";
const GetCertificate = async ({payload})=>{
  const data = new FormData()
  data.append("start_date",date)
  data.append("end_date",date)
  data.append("program","Workshop")
  data.append("name",item.name)
  data.append("id",(20240303000+item.id))
  data.append('gender',item.gender?item.gender:"male")
  data.append("department",null)
  data.append("role","Internship")
  data.append("certificate_code",certificate)
  if(payload.save){
      data.append("save",true)
  }
  data.append("issued_date","4th Mar 2024")
  const res = await axios.post(ENDPOINT+"certificate-template.php",data,SecurityHeaders)
  if(res){
  
       // console.log(res)
      setcertificate(res.data)
      setpreview(true)
     // const certificate = document.getElementById("certificate")
      //certificate.innerHTML = res.data
  }
}

const [preview,setpreview] = useState(false)
const [certificate,setcertificate] = useState(null)
  return (
  
  <>

  <DefaultModal show={preview} HandleClose={()=>setpreview(false)}
  content={<>
  <div className=''>

{certificate}

  </div>
  
  </>}
  
  />
<DefaultModal className="col-lg-4" show={edit} HandleClose={CloseEdit} content={<>
   <EditWorkshopRegistration/>
   </>}/>
   
  <DeleteModal show={show} HandleClose={HandleClose} HandleDelete={HandleDelete}/>

  <div className="container-fluid d-flex">
  <div className="col-lg-4 col-6 d-flex align-items-center">
  <div className="form-check">
                                <input
checked={selected}
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkAll"
                                  onClick={()=>{SelectUser();setselected(!selected)}}
                                  defaultValue="option1"
                                />
                              </div>
<div>


   
                           <a target='_blank' href={"https://wa.me/"+item.wa_phone}> <div>
                              <h6>{item.name}</h6>
                              <p className='d-md-none d-block'>{dateFormat(item.create_datetime,"dS mmm hh:MM tt")}</p></div>
                              </a>
</div>
  </div>
  <div className="col-lg-2">
    <h6>{item.phone}</h6>
  </div>
  <div className="col-lg-3 d-none d-md-block">
    <h6>{item.email}</h6>
  </div>

  <div className="col-lg-2">
    <h6>{item.status}</h6>
  </div>
  <div className="">

    <div className="d-flex">

      <Preview  className='mx-2 click' onClick={()=>GetCertificate({payload:{"save":true}})}/>
<Save className='mx-2 click' onClick={()=>GetCertificate({payload:{}})}/>
<Dropdown>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic">
        <MoreVert/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item ><Delete/> Delete</Dropdown.Item>
        <Dropdown.Item ><AttachEmail/> Send Email</Dropdown.Item>
        <Dropdown.Item ><ScheduleSend/> Send Meeting Details</Dropdown.Item>
        <Dropdown.Item ><WhatsApp/> Send WhatsApp Group Link</Dropdown.Item>
        


        
      </Dropdown.Menu>
    </Dropdown>  
    </div>
 
  </div>
</div>
  <tr className='d-none'>
                            <th scope="row">
                           
                            </th>
                          
                            <td className="customer_name">
                            
                            
                           <a href={"https://wa.me/"+item.wa_phone}> <div>
                              <h6>{item.name}</h6>
                              <p className='d-md-none d-block'>{dateFormat(item.create_datetime,"dS mmm hh:MM tt")}</p></div>
                              </a>
                              
                              </td>
                            <td className="product_name" >
                              <a href={"https://wa.me/91"+item.phone}> {item.phone}</a>
                             </td>
                            <td className="date d-none d-md-block">
                              {item.email}
                              </td>
                            <td className="amount d-none d-md-block">{item.create_datetime}</td>
                         
                            <td className="status d-none d-md-block">
                              <span className="badge bg-warning-subtle text-warning text-uppercase">
                               {item.status}
                              </span>
                            </td>
                            <td>
                              <ul className="list-inline hstack gap-2 mb-0">
                                <li
                                  className="list-inline-item"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  data-bs-placement="top"
                                  title="View"
                                >
                                  <a
                                    href="apps-ecommerce-order-details.html"
                                    className="text-primary d-inline-block"
                                  >
                                    <i className="ri-eye-fill fs-16" />
                                  </a>
                                </li>
                                <li
                                  className="list-inline-item edit"
                                 onClick={()=>setedit(true)}
                                  title="Edit"
                                >
                                  <a
                          
                                    className="text-primary d-inline-block edit-item-btn"
                                  >
                                    <i className="ri-pencil-fill fs-16" />
                                  </a>
                                </li>
                                <li
                                  className="list-inline-item"
                             
                                  onClick={()=>setshow(true)}
                                  title="Remove"
                                >
                                  <a
                                    className="text-danger d-inline-block remove-item-btn"
                                   
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16" />
                                  </a>
                                </li>
                              </ul>
                            </td>

                            <td className="amount">
        
                                </td>
                          </tr>
  </>
    )
}

export default RegistrationItem