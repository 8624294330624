import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const StudentSupportChart = () => {
  const [series] = useState([50, 30, 20]); // Example data for Support Requested, In Progress, Resolved

  const options = {
    chart: {
      type: 'pie',
      width: 350,
      padding:30,
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 10,
        left: 0,
        blur: 8,
        opacity: 0.2,
        
      },
    },
    title: {
      text: 'Customer Support Status',
      align: 'center',
      margin: 20,
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',
      },
    },
    labels: ['Closed', 'Initiate', 'UnResolved'],
    colors: ['#73BFB8', '#FFD166', '#EF476F'], // Updated colors for each status
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: ['#56A0A8', '#FFBC4A', '#D1435B'],
        stops: [0, 100],
        opacityFrom: 0.8,
        opacityTo: 0.9,
      },
    },
    stroke: {
      width: 3,
      colors: ['#fff'],
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => `${opts.w.config.labels[opts.seriesIndex]}: ${Math.round(val)}%`,
      style: {
        colors: ['#333'],
        fontSize: '14px',
        fontWeight: 'bold',
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 6,
      },
    },
    legend: {
      position: 'bottom',
      fontSize: '10px',
      fontWeight: 600,
      labels: {
        colors: ['#333'],
      },
      markers: {
        width: 10,
        height: 10,
        radius: 5,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 280,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div style={styles.container}>
      
      <div style={styles.chartContainer}>
        <ReactApexChart options={options} series={series} type="pie" width={470} />
      </div>
    </div>
  );
};

// Enhanced styles for an attractive layout
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    border:'1px solid gray',
    padding: '25px',
    
    
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    // maxWidth: '500px',
    margin: 'auto',
  },
  card:{
    padding: '25px',
  },
  
 
};

export default StudentSupportChart;
