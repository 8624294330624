import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ModeWiseCourseRegistration = () => {
  const [series, setSeries] = useState({
    series: [{
      name: 'Success',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66,76,56,87]
    }, {
      name: 'Pending',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94,100,87,94]
    }, {
      name: 'Pause',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41,43,35,55]
    }],
    options: {
      title: {
        text: 'Course Registerations Status',
        align: 'center',
        margin: 20,
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#333',
        },
      },
      chart: {
        type: 'bar',
        height: 450
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','dec'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  });

  return (
    
     <div className=' m-3 rounded '>
    
      <div >
        <ReactApexChart 
          options={series.options}
          series={series.series}
          type="bar"
          height={425}
          
        />
     </div>
    </div>
  );
};

// Enhanced styles for layout and appearance


export default ModeWiseCourseRegistration;
