import React, { useContext, useEffect, useState } from 'react'

import { FormControlLabel, Switch } from '@material-ui/core'
import Alert from 'react-bootstrap/Alert'
import { styled } from '@mui/material/styles';
import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';
import { Toast } from 'react-bootstrap';
const PermissionsManagement = ({userid}) => {
  useEffect(()=>{
    window.scrollTo(0,0)
    
    },[])
const loginnotif = useRef()
const {user} = useContext(AuthContext)

const params = useParams()
const user_id = userid?userid:params.user_id
const [key,setkey] = useState(null);
const [value,setvalue] = useState(false)

const [status,setstatus]= useState(false);
const [toast,settoast] = useState(false)


const [preferences,setpreferences] = useState(null)

const updatePreferences = async ({key2,val})=>{
        

  const formData = new FormData();
  
  
  //formData.append('whyus',document.getElementById('whyus').value)
  
  formData.append('session',user.SessionId);
  formData.append("user_id",user_id)
    formData.append('key',key2);
     if(val===false){
      formData.append('value',0);
     // //  // console.log("key is"+key2+" val "+0)

    }else{

      formData.append('value',1);
    //  //  // console.log("key is"+key2+" val "+1)


    }

  const res = await axios.post(ENDPOINT+"update-team-permissions.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){
////  // console.log(" data "+JSON.stringify(res))
   if(res.data.status==="success"){
     setstatus(true)
    settoast(true)
    getPreferences()
   }
   else{
     settoast(false)
  settoast(true)
  getPreferences()
   }
  }
  }
  




const [pref,setpref] = useState(null)


const [account_login,setaccount_login] = useState(0)

//GET PREFERENCES
const getPreferences = async ()=>{
        

  const formData = new FormData();
  
  
 
  formData.append('session',user.SessionId);
  formData.append("user_id",user_id)


  const res = await axios.post(ENDPOINT+"get-team-permissions.php", formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  });
  if(res){

 
   if(res.data.status==="success"&&res.data.data!==null){

    // //  // console.log("preferences "+JSON.stringify(res.data.data))
     setpreferences(res.data.data)
     const data = res.data.data;
     setaccount_login(data.account_login)
     setpref(res.data.data)
   //  //  // console.log("data is "+JSON.stringify(res.data))
    
     setstatus(true)
 
   // //  // console.log("server stat "+res.data.data.upcoming_classes)
   }
   else if(res.data.status==="success"&&res.data.data===null){
    setpreferences(res.data.data)

    
   }
   else{
     //error
   }
  }
  }


useEffect(()=>{
getPreferences()

},[])


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          marginleft:10,
          margintop:5
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
      








    return (
     
<>

{preferences?
<>

<div className="container-fluid p-2">


<div className="position-fixed bottom-60 left-0 container-fluid justify-center">


<Toast className={status?"bg-warning":"bg-warning"} onClose={() => settoast(false)} show={toast} delay={3000} autohide>
         
         
         
       

          <Toast.Body>{status?"Changes Saved Successfully !":"Something went wrong !"}</Toast.Body>
          </Toast>
</div>


<div className="flex-wrap bg-white mb-5 pt-3 ">








<div className="col-lg-7 mt-0">
























































































{/*section starts*/}






{/*section ends*/}














{/*bharati section starts*/}




<div className="setting-section mt-5 p-2 w-100">
<h5>Permissions</h5>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Super Admin
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.super_admin==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"super_admin",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>




<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Ambassador profile
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.ambassador_profile==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"ambassador_profile",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Intern
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.intern==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"intern",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>




<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">Quick Actions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.quick_actions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"quick_actions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p  className="mb-0">User Counts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.users_counts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"users_counts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>




<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Sales Statistics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.sales_statistics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"sales_statistics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Doubt support Statistics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.doubt_support_statistics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"doubt_support_statistics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Customer support Statistics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.customer_support_statistics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"customer_support_statistics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Placement support Statistics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.placement_support_statistics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"placement_support_statistics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">leave request Statistics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.leave_request_statistics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"leave_request_statistics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Leads Statistics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.leads_statistics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"leads_statistics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Users
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.users==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"users",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Students
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.students==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"students",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Interns
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.interns==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"interns",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Trainers
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.trainers==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"trainers",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Companies
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.companies==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"companies",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Purchase Price
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.purchase_price==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"purchase_price",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Transactions 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.transactions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"transactions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Leads 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Customer Support 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.customer_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"customer_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Whatsapp Chat 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.whatsapp_chat==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"whatsapp_chat",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Doubt Support 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.doubt_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"doubt_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Users Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.users_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"users_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Students Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.students_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"students_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Interns Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.interns_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"interns_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Community Management 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.community_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"community_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Quiz Management 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.quiz_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"quiz_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team tasks
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_tasks==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_tasks",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student tasks
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_tasks==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_tasks",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Courses Management 
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.courses_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"courses_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Internships Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internships_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internships_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Placement Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.placement_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"placement_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Videos Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.videos_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"videos_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Classes Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.classes_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"classes_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Idcard Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.idcard_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"idcard_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Certificate Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.certificate_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"certificate_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Activity Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.activity_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"activity_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Expenses Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.expenses_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"expenses_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mocktests Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mock_tests_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mock_tests_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Resources Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.resources_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"resources_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"/>}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Interviewkits Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.interview_kits_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"interview_kits_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"/>}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Coursecheat Sheets
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_cheat_sheets==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_cheat_sheets",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Technicalquiz Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.technical_quiz_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"technical_quiz_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team milestones Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_milestones_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_milestones_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Couponcodes Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.coupon_codes_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"coupon_codes_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Remainders
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_remainders==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_remainders",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Remainders
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_remainders==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_remainders",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Lead
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_lead==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_lead",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Addteam Task
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_team_task==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_team_task",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View team Task
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_team_task==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_team_task",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Deleteteam Task
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_team_task==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_team_task",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Evaluateteam Task
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.evaluate_team_task==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"evaluate_team_task",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Addteam Milestone
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_team_milestone==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_team_milestone",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Leaves Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.leaves_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"leaves_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team attendance Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_attendance_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_attendance_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Approve Leaves
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.approve_leaves==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"approve_leaves",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upload Video
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upload_video==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upload_video",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Attendance
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_attendance==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_attendance",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Website Settings
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.website_settings==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"website_settings",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Permissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_permissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_permissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Viewteam Targets
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_team_targets==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_team_targets",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Addteam Targets
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_team_targets==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_team_targets",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Deleteteam Targets
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_team_targets==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_team_targets",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Meetings
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_meetings==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_meetings",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Addteam Meeting
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_team_meeting==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_team_meeting",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Deleteteam Meeting
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_team_meeting==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_team_meeting",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Phone
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_phone==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_phone",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Email
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_email==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_email",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Whatsapp
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_whatsapp==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_whatsapp",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upcomimg Classes
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upcoming_classes==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upcoming_classes",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Website Log
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.website_log==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"website_log",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Active Batches
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.active_batches==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"active_batches",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Shortsm Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.shorts_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"shorts_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Hacking Workshop Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.hacking_workshop_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"hacking_workshop_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Hacking Internship Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.hacking_internship_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"hacking_internship_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Start Class
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.start_class==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"start_class",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upload Class Recording
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upload_class_recording==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upload_class_recording",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Class Topic
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_class_topic==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_class_topic",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Share Class Meeting Link
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.share_class_meeting_link==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"share_class_meeting_link",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Preview Class Recording
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.preview_class_recording==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"preview_class_recording",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Share Class Recording
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.share_class_recording==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"share_class_recording",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Delete Class Schedule
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_class_schedule==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_class_schedule",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Overview
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_overview==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_overview",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Attendance
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_attendance==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_attendance",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Classes
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_classes==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_classes",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Assignments
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_assignments==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_assignments",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Projects
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_projects==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_projects",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Assignment Submissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_assignment_submissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_assignment_submissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Project Submissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_project_submissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_project_submissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Batch Report
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_batch_report==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_batch_report",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Class Schedule
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_class_schedule==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_class_schedule",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Assignment
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_assignment==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_assignment",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Delete Assignment
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_assignment==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_assignment",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Evaluate Assignment
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.evaluate_assignment==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"evaluate_assignment",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Assignment
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_assignment==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_assignment",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Project
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_project==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_project",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Delete Project
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_project==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_project",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Project
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_project==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_project",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Delete Project Submission
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_project_submission==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_project_submission",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Evaluate Project Submission
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.evaluate_project_submission==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"evaluate_project_submission",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Batch Students
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_batch_students==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_batch_students",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Overview
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_overview==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_overview",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Initiate Placement Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.initiate_placement_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"initiate_placement_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Lead
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_lead==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_lead",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mark Lead Interested
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mark_lead_interested==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mark_lead_interested",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mark Lead Scheduled
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mark_lead_scheduled==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mark_lead_scheduled",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mark Lead Pendingpayment
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mark_lead_pendingpayment==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mark_lead_pendingpayment",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mark Lead Joined
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mark_lead_joined==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mark_lead_joined",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Scheduled Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_scheduled_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_scheduled_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Interested Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_interested_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_interested_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Notintrested Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_notintrested_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_notintrested_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Pending Payment Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_pending_payment_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_pending_payment_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Joined Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_joined_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_joined_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div> 

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Students List
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.students_list==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"students_list",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Assignment Submissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.assignment_submissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"assignment_submissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Project Submissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.project_submissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"project_submissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Team Profile
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_team_profile==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_team_profile",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Doubts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.doubts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"doubts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Courses
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.courses==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"courses",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">All Batches
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.all_batches==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"all_batches",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">All Class Schedules
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.all_class_schedules==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"all_class_schedules",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Recordings
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.recordings==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"recordings",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Fee Payments
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.fee_payments==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"fee_payments",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team List
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_list==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_list",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Joined Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_joined_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_joined_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Posts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.posts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"posts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Comments
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.comments==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"comments",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Messages
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.messages==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"messages",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Joined Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_joined_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_joined_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Placement Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.placement_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"placement_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Intenships
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.intenships==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"	intenships",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Intenship Applications
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.intenship_applications==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"intenship_applications",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Tutorials
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.tutorials==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"tutorials",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Joined Leads
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_joined_leads==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_joined_leads",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Shorts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.shorts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"shorts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Super Admin Dashboard
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.super_admin_dashboard==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"super_admin_dashboard",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Payments
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.payments==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"payments",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Daily Reports
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.daily_reports==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"	daily_reports",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Security
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.security==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"	security",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Coupon Codes
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.coupon_codes==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"coupon_codes",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Withdrawls
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.withdrawls==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"withdrawls",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Sign In
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.sign_in==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"sign_in",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Create Invoice-2
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.create_invoice_2==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"create_invoice_2",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Invoices List
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.invoices_list==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"invoices_list",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Project Overview
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.project_overview==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"project_overview",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Course Registrations
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_registrations==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_registrations",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">User Lists
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.user_lists==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"user_lists",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Analytics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.analytics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"analytics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Projects Dashboard
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.projects_dashboard==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"projects_dashboard",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Workshop Registrations
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.workshop_registrations==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"workshop_registrations",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Campus Ambassadors
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.campus_ambassadors==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"campus_ambassadors",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Job Hunt
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.job_hunt==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"job_hunt",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Wallet
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.wallet==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"wallet",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upload Videos
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upload_videos==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upload_videos",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Tasks
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.tasks==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"tasks",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Attendence
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.attendence==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"attendence",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Leaves
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.leaves==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"leaves",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Meetings
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.meetings==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"meetings",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Leaderboard
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_leaderboard==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_leaderboard",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upload Shorts Video
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upload_shorts_video==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upload_shorts_video",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Course Overview
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_overview==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_overview",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Course
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_course==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_course",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Syllabus
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.syllabus==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"syllabus",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Course Resources
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_resources==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_resources",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Course Certifications
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_certifications==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_certifications",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mock Tests
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mock_tests==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mock_tests",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Technical_Quizzes
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.technical_quizzes==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"technical_quizzes",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Profile
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_profile==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_profile",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Download Shorts Data
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.download_shorts_data==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"download_shorts_data",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Video
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_video==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_video",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Delete Video
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_video==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_video",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Student Profile
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_student_profile==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_student_profile",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">View Class Recording
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.view_class_recording==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"view_class_recording",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Reports
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_reports==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_reports",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Idcards
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_idcards==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_idcards",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Profect Submissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_profect_submissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_profect_submissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Personal Badges
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.personal_badges==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"personal_badges",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Assignment Submissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_assignment_submissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_assignment_submissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Doubts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_doubts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_doubts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Project Submissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_project_submissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_project_submissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Activate Course
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.activate_course==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"activate_course",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mark Course Refunded
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mark_course_refunded==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mark_course_refunded",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Refund Course
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.refund_course==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"refund_course",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mark Course Not Interested
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mark_course_not_interested==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mark_course_not_interested",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Pause Course
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.pause_course==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"pause_course",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Delete Course Payment
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_course_payment==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_course_payment",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Initiate Student Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.initiate_student_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"initiate_student_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Close Student Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.close_student_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"close_student_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Delete Student Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.delete_student_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"delete_student_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Course Badges
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_badges==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_badges",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Screen Shots
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.screen_shots==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"screen_shots",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Reschedule Class
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.reschedule_class==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"reschedule_class",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Recent Recordings
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.recent_recordings==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"recent_recordings",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Test
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.test==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"test",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Class Feedbacks
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.class_feedbacks==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"class_feedbacks",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Payment Screenshots
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.payment_screenshots==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"payment_screenshots",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Customer Support Admin
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.customer_support_admin==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"customer_support_admin",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Marketing
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.marketing==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"marketing",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Placement Cell Leaderboard
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.placement_cell_leaderboard==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"placement_cell_leaderboard",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Quiz Attempts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.quiz_attempts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"quiz_attempts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Mile Stone
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.mile_stone==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"mile_stone",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Whatsapp Support
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.whatsapp_support==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"whatsapp_support",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">College Registrations
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.college_registrations==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"college_registrations",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Daily Report
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_daily_report==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_daily_report",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Job Applications
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.job_applications==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"job_applications",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Create Invoice-1
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.create_invoice_1==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"create_invoice_1",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Products
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.products==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"products",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">	Chat
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.chat==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"chat",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Job Dashboard
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.job_dashboard==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"job_dashboard",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Edit Course
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.edit_course==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"edit_course",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Notifications
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.notifications==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"notifications",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">About
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.about==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"about",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Permissions
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.permissions==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"permissions",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Internships
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_internships==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_internships",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>



<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batch Management
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batch_management==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batch_management",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Complete Analytics
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.complete_analytics==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"complete_analytics",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Projects
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.projects==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"projects",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Videos
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.videos==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"videos",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upload Image
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upload_image==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upload_image",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Get Images
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.get_images==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"get_images",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Placement Students
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.placement_students==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"placement_students",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Students
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_students==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_students",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Members All
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_members_all==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_members_all",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Courses
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_courses==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_courses",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Add Batch
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.add_batch==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"add_batch",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Reviews
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.reviews==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"reviews",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Batches
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.batches==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"batches",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Internship List
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.internship_list==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"internship_list",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Update Shorts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.update_shorts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"update_shorts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Member
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_member==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_member",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Hacking Workshop Registrations
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.hacking_workshop_registrations==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"hacking_workshop_registrations",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Hacking Internship Registrations
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.hacking_internship_registrations==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"hacking_internship_registrations",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Team Leaves
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.team_leaves==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"team_leaves",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Contact
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.contact==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"contact",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Create Certificates
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.create_certificates==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"create_certificates",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Free Resources
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.free_resources==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"free_resources",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">All Charts
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.all_charts==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"all_charts",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Assignment Submission Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.assignment_submission_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"assignment_submission_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Totalcourseregistrations Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.totalcourseregistrations_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"totalcourseregistrations_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Projectsubmission Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.projectsubmission_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"projectsubmission_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Users Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.users_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"users_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>


<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upcoming Class
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upcoming_class==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upcoming_class",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Paymentstatus Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.paymentstatus_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"paymentstatus_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Paymentpay Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.paymentpay_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"paymentpay_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Amountstatus Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.amountstatus_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"amountstatus_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Leads Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.leads_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"leads_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Studentsupport Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.studentsupport_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"studentsupport_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Upload Team Image
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.upload_team_image==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"upload_team_image",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Image Internal Manager
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.image_internal_manager==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"image_internal_manager",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Course Registration Status Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_registration_status_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_registration_status_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Course Registration Chart	
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.course_registration_chart	==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"course_registration_chart	",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Customer Support Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.customer_support_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"customer_support_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Doubt Support Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.doubt_support_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"doubt_support_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Placement Support Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.placement_support_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"placement_support_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>

<div className="flex space-between align-items-center ">
    <div>
<p className="mb-0">Student Support Chart
</p>
  </div>
     <div>
     <FormControlLabel
        checked={preferences.student_support_chart==="1"?true:false}
        onChange={(e)=>{updatePreferences({key2:"student_support_chart",val:e.target.checked}); }}
        control={<IOSSwitch sx={{ m: 1 }} color="primary"    />}
             />
     </div>
</div>
























</div>


{/*bharathi section ends*/}





















































</div>

<div className="col-lg-5 pt-5 justify-center">
    <div className="w-50 text-center">
    <img src={preferences} className="w-100" alt=""></img>
    <p>If you'd like to learn more about privacy , you can read about it in the <Link to="/privacy-policy">Privacy Policy</Link></p>
    </div>
</div>



</div>




</div>

</>:

<>

</>

}


</>

    
    )
}

export default PermissionsManagement


