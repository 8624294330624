import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

function PaymentPayChart() {
  const chartSeries = useMemo(() => [70, 30], []);

  const chartOptions = useMemo(() => ({
    chart: {
      type: 'donut',
      width: 400,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      },
      dropShadow: {
        enabled: true,
        top: 2,
        left: 2,
        blur: 15,
        color: '#000',
        opacity: 0.3,
      },
    },
    labels: ['Full Payment', 'EMI'],
    title: {
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',
      },
    },
    colors: ['#34A853', '#FBBC05'],
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: '70%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              color: '#333',
              fontSize: '16px',
              fontWeight: 'bold',
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(1)}%`,
      style: {
        colors: ['#444'],
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    legend: {
      position: 'bottom',
      fontSize: '16px',
      fontWeight: 'bold',
      labels: {
        colors: ['#333'],
      },
      markers: {
        width: 12,
        height: 12,
        radius: 3,
      },
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      y: {
        formatter: (val) => `${val}%`,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }), []);

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Payment Method</h2>
      <div style={styles.card}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width={400}
        />
      </div>
    </div>
  );
}

// Shared styles
const styles = {
  container: {
    // boxShadow: '0 10px 30px rgba(0, 0, 0, 0.15)',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#222',
    marginBottom: '20px',
    textAlign: 'center',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
  },
  card: {
    padding: '30px',
    borderRadius: '12px',
    // background: 'linear-gradient(135deg, #ffffff 0%, #f7f8fa 100%)',
    maxWidth: '450px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default PaymentPayChart;
