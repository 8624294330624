import axios from 'axios'
import React, { useEffect } from 'react'
import SecurityHeaders from '../../context/SecurityHeaders'

const InternalImageManager = () => {
    const Images = async() => {
        const data = new FormData()

        var res = await axios.post("https://skillsuprise.com/get-photos.php",data,SecurityHeaders);
        if(res){
            console.log(res)
        }
    }
   
    useEffect(()=>{
        Images()
    },[])
  return (
    <div>InternalImageManager</div>
  )
}

export default InternalImageManager