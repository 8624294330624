import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ProjectSubmission = () => {
  const series = [401, 247]; // 401 for "Submitted" and 247 for "Evaluated"

  const options = {
    chart: {
      type: 'pie',
      width: 380, // Set the initial width
    },
    labels: ["Submitted", "Evaluated"],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        opacity: 0.3,
      },
      offsetY: -10, // Bring the labels closer to the center of the pie
      formatter: (value, opts) => {
        return opts.w.globals.series[opts.seriesIndex] + " projects"; // Format the labels with project counts
      },
    },
    legend: {
      position: 'bottom', // Position the legend below the chart
      markers: {
        width: 12,
        height: 12,
      },
      fontSize: '14px',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300, // Adjust chart size on smaller screens
        },
        legend: {
          position: 'bottom',
          fontSize: '12px',
        },
      },
    }],
    tooltip: {
      y: {
        formatter: (value) => `${value} projects`,
      },
    },
    colors: ['#4CAF50', '#FF9800'], // Custom colors for clarity
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="pie" height={270}/>
      </div>
    </div>
  );
};

export default ProjectSubmission;
